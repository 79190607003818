<template>
  <div>
    <v-row
      @keydown.enter.prevent="closeDatetimePicker"
    >
<!--      Filters button-->
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-btn
          class="mt-4"
          plain
          :disabled="!!history"
          @click="canShowFiltersDialog = !history"
        >
          <v-icon
            large
          >
            mdi-filter-variant
          </v-icon>
          <span
            class="ml-5"
          >
            Filteri
          </span>
        </v-btn>
      </v-col>

<!--      From datetime picker chooser-->
      <v-col
        cols="auto"
        md="auto"
        lg="auto"
        xl="auto"
      >
        <v-menu
          v-model="fromMenu"
          transition="slide-y-transition"
          nudge-top="450"
          offset-y
          min-width="auto"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromFormatted"
              :disabled="history === 'true'"
              :label="$t('base.from')"
              prepend-inner-icon="mdi-calendar-start"
              readonly
              v-bind="attrs"
              v-on="on"
              @blur="parseDmyDate(fromFormatted)"
            />
          </template>
          <datetime-picker
            v-model="from"
            :emit-default-time="true"
            :default-time="'00:00:00'"
            :min-date="minFromDate"
            :max-date="this.to"
            :reset-tabs="resetFromTabs"
            @close-picker="closeDatetimePicker"
            :on-double-click="closeDatetimePicker"
          >
            <v-col class="white text-right pb-2 px-2">
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="closeDatetimePicker"
              >
                {{ $t('base.ok') }}
              </v-btn>
            </v-col>
          </datetime-picker>
        </v-menu>
      </v-col>

<!--      To datetime picker choose-->
      <v-col
        cols="auto"
        md="auto"
        lg="auto"
        xl="auto"
      >
        <v-menu
          v-model="toMenu"
          transition="slide-y-transition"
          nudge-top="450"
          offset-y
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="toFormatted"
              :label="$t('base.to')"
              :disabled="history === 'true'"
              prepend-inner-icon="mdi-calendar-end"
              readonly
              v-bind="attrs"
              v-on="on"
              @blur="parseDmyDate(toFormatted)"
            />
          </template>
          <datetime-picker
            v-model="to"
            :emit-default-time="true"
            :default-time="defaultToTime"
            :min-date="minDate"
            :reset-tabs="resetToTabs"
            @close-picker="closeDatetimePicker"
            :on-double-click="closeDatetimePicker"
          >
            <v-col class="white text-right pb-2 px-2">
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="closeDatetimePicker"
              >
                {{ $t('base.ok') }}
              </v-btn>
            </v-col>
          </datetime-picker>
        </v-menu>
      </v-col>

<!--      Show table button-->
      <v-col
        cols="auto"
        md="auto"
        lg="auto"
        xl="auto"
      >
        <v-btn
          class="mt-4"
          plain
          @click="getFiltered"
        >
          <v-icon
            large
          >
            mdi-file-table
          </v-icon>
          <span
            class="ml-5"
          >
            {{ $t('road-maintenance/patrol-service.show_order_list') }}
          </span>
        </v-btn>
      </v-col>

<!--      Export button-->
      <v-col
        cols="auto"
        md="auto"
        lg="auto"
        xl="auto"
      >
        <v-btn
          class="mt-4"
          plain
          @click="exportData"
        >
          <v-icon
            large
          >
            mdi-application-export
          </v-icon>
          <span
            class="ml-5"
          >
            {{ $t('road-maintenance/excavation-service.export') }}
          </span>
        </v-btn>
      </v-col>

<!--      History switch button-->
      <v-col
        cols="auto"
        md="auto"
        lg="auto"
        xl="auto"
      >
        <v-switch
          v-model="history"
          v-show="historySwitchVisible && !active"
          :label="$t('road-maintenance/patrol-service.show_history').toUpperCase()"
          color="green"
          value="true"
          hide-details
        />
      </v-col>
    </v-row>

<!--    Filter dialog-->
    <v-dialog
      v-model="canShowFiltersDialog"
      max-width="1024px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
      :transition="false"
    >
      <v-card
        style="max-height: 70vh; display: flex; flex-direction: column;"
      >
        <v-card-title
          class="v-card-title"
        >
          Filteri
          <v-spacer />

          <universal-button
            type="base"
            :ripple="false"
            :elevation="0"
            class="no-background-hover"
            style="border: unset !important; color: #6B7280;"
            append-icon="mdi-close"
            :append-icon-style="{ fontSize: '27px !important' }"
            @click="closeFilterDialog"
          />
        </v-card-title>
        <v-card-text
          style="overflow-y: auto; flex-grow: 1;"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="tipNumber"
                  label="Broj dojave"
                  clearable
                  autofocus
                />
                <v-text-field
                  v-model="address"
                  label="Adresa"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 6"
              >
                <h3>Subjekti dojave</h3>
                <v-switch
                  v-model="selectedSubjectReport"
                  v-for="subjectReport in filters.subject_report"
                  :key="subjectReport.id"
                  color="primary"
                  :value="subjectReport.id"
                  :label="subjectReport.road_administration_name"
                  hide-details
                />
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 3"
              >
                <h3>Razina opasnosti</h3>
                <v-switch
                  v-model="selectedHazardLevel"
                  v-for="hazardLevel in filters.hazard_level"
                  :key="hazardLevel.id"
                  color="primary"
                  :value="hazardLevel.id"
                  :label="$t(hazardLevel.description)"
                  hide-details
                />
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 3"
              >
                <h3>Gradske četvrti</h3>
                <v-switch
                  v-model="selectedCityDistricts"
                  v-for="cityDistrict in filters.city_districts"
                  :key="cityDistrict.id"
                  color="primary"
                  :value="cityDistrict.id"
                  :label="cityDistrict.name"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions
          style="bottom: 0;"
        >
          <v-row class="ma-0 flex flex-row-reverse mt-4">
            <v-btn
              color="primary"
              primary
              class="px-7 ml-5 mt-6"
              @click="closeFilterDialog"
            >
              {{ $t('base.ok') }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay
      :value="exportLoading"
      z-index="1000"
    >
      <v-progress-circular
        indeterminate
        size="60"
      />
    </v-overlay>
  </div>
</template>

<script>
import DatetimePicker from '@/global/components/DatetimePicker.vue'
import { formatSqlDateTime, parseDmyDate } from '@/global/services/helpers/dates'
import dayjs from 'dayjs'
import { api } from '@/global/services/api'
import KeyBinder from '@/global/services/helpers/KeyBinder'
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'

const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

export default {
  name: 'WorkOrderControls',

  components: {
    UniversalButton,
    DatetimePicker
  },

  props: {
    historySwitchVisible: {
      type: Boolean,
      default: false
    },
    resetHistory: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      today: new Date().toISOString().slice(0, 10),
      from: null,
      fromFormatted: null,
      to: null,
      toFormatted: null,
      companyScopeId: null,
      fromMenu: false,
      toMenu: false,
      history: false,
      resetFromTabs: false,
      resetToTabs: false,
      filters: {},
      address: null,
      tipNumber: null,
      canShowFiltersDialog: false,
      selectedHazardLevel: [],
      selectedSubjectReport: [],
      selectedCityDistricts: [],
      exportLoading: false
    }
  },

  computed: {
    active () {
      return this.$route.name === 'ActiveWorkOrders'
    },

    defaultToTime () {
      return '23:59:59'
    },

    minFromDate () {
      return dayjs(this.to).subtract(1, 'year').toISOString()
    },

    minDate () {
      return dayjs(this.from).format('YYYY-MM-DD')
    }
  },

  watch: {
    from (from) {
      this.fromFormatted = formatSqlDateTime(from, 'DD.MM.YYYY HH:mm')
    },

    to (to) {
      this.toFormatted = formatSqlDateTime(to, 'DD.MM.YYYY HH:mm')
    },

    fromMenu (value) {
      this.$emit('dialog-open-status', value)
      this.resetFromTabs = !this.resetFromTabs
    },

    toMenu (value) {
      this.$emit('dialog-open-status', value)
      this.resetToTabs = !this.resetToTabs
    },

    history (value) {
      this.$emit('show-history', value)
    },

    historySwitchVisible (value) {
      if (!value) {
        this.history = false
      }
    },

    resetHistory (value) {
      if (value) {
        this.history = false
        this.$emit('reset-history-trigger')
      }
    }
  },

  created () {
    this.from = dayjs().subtract(6, 'months').toISOString()
    this.to = dayjs().toISOString()
    this.fetchFilters()
    this.emitOptions()
    this.keyCallbacks = {
      f: this.handleKeyDownF,
      F: this.handleKeyDownF,
      Enter: this.handleKeyDownEnter,
      Escape: this.handleKeyDownEscape
    }
    this.keyBinder = new KeyBinder(this.keyCallbacks)
    this.keyBinder.bind()
    this.$emit('save-bind-class', this.keyBinder)
  },

  destroyed () {
    if (this.keyBinder) {
      this.keyBinder.unbind()
    }
  },

  methods: {
    handleKeyDownF () {
      if (!this.isModalOpen && !this.canShowFiltersDialog) {
        this.canShowFiltersDialog = true
      }
    },

    handleKeyDownEscape () {
      if (this.canShowFiltersDialog) {
        this.canShowFiltersDialog = false
      }
    },

    handleKeyDownEnter () {
      if (!this.isModalOpen && this.canShowFiltersDialog) {
        this.closeFilterDialog()
        this.$emit('show-table')
      }
    },

    closeDatetimePicker () {
      this.emitOptions()
      if (this.fromMenu) {
        this.fromMenu = false
      }
      if (this.toMenu) {
        this.toMenu = false
      }
    },

    parseDmyDate (date) {
      return parseDmyDate(date)
    },

    async fetchFilters () {
      const fetched = await api('road-maintenance').get('/work-orders-filters')
      this.filters.subject_report = fetched.subject_report
      this.selectedSubjectReport = this.filters.subject_report.map(item => item.id)
      this.filters.hazard_level = fetched.hazard_level
      this.selectedHazardLevel = this.filters.hazard_level.map(item => item.id)
      this.filters.city_districts = fetched.city_districts
      this.selectedCityDistricts = this.filters.city_districts.map(item => item.id)
    },

    getFiltered () {
      if (!this.history) {
        this.emitOptions()
        this.history = false
        this.$emit('reset-history-trigger')
        this.$emit('show-table')
      }
    },

    emitOptions () {
      this.$emit('get-options', {
        from: formatSqlDateTime(this.from, 'YYYY-MM-DD HH:mm:ss'),
        to: formatSqlDateTime(this.to, 'YYYY-MM-DD HH:mm:ss'),
        selectedHazardLevel: this.selectedHazardLevel,
        selectedSubjectReport: this.selectedSubjectReport,
        selectedCityDistricts: this.selectedCityDistricts,
        address: this.address,
        tipNumber: this.tipNumber
      })
    },

    closeFilterDialog () {
      if (this.selectedSubjectReport?.length && this.selectedHazardLevel?.length && this.selectedCityDistricts?.length) {
        this.emitOptions()
        this.canShowFiltersDialog = false
      }
      else {
        alert(this.$t('road-maintenance/patrol-service.filter_alert'))
      }
    },

    async exportData () {
      const columns = [
        'id',
        'created_at',
        'created_by',
        'subject_report',
        'category',
        'hazard_level',
        'address',
        'description'
      ]
      if (!this.active) {
        columns.push('completed_at', 'completed_by')
      }
      const params = {
        columns: columns,
        filters: {
          active: this.active,
          hazard_level: this.selectedHazardLevel,
          subject_report: this.selectedSubjectReport,
          city_districts: this.selectedCityDistricts,
          from: formatSqlDateTime(this.from, 'YYYY-MM-DD HH:mm:ss'),
          to: formatSqlDateTime(this.to, 'YYYY-MM-DD HH:mm:ss'),
          address: this.address,
          tipNumber: this.tipNumber
        }
      }
      this.exportLoading = true
      const url = new URL(`${window.location.origin}/api/road-maintenance/patrol-order-export`)
      params.columns.forEach(column => url.searchParams.append('columns[]', column))
      url.searchParams.append('filters', JSON.stringify(params.filters))
      let objectURL = null
      fetch(url.toString())
        .then(res => {
          if (!res.ok) {
            throw new Error(res.statusText)
          }
          return res.arrayBuffer()
        })
        .then(arrayBuffer => {
          // BE endpoint sends a readable stream of bytes
          const byteArray = new Uint8Array(arrayBuffer)
          const link = document.createElement('a')
          objectURL = URL.createObjectURL(
            new Blob(
              [byteArray],
              { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
            )
          )
          link.href = objectURL
          const filename = this.active ? this.$t('road-maintenance.patrol-service-active-orders') : this.$t('road-maintenance.patrol-service-inactive-orders')
          link.download = `${filename}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .finally(() => {
          this.exportLoading = false
          if (objectURL) URL.revokeObjectURL(objectURL)
        })
    }
  }
}
</script>

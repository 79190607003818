<template>
  <v-container
    fluid
    :class="containerClasses"
    style="position: relative"
  >
    <v-overlay
      v-if="showOverlay"
      style="left: 12px; bottom: 12px"
      opacity="0.2"
      absolute
    />
    <v-card :disabled="fetchingData">
      <v-data-table
        v-model="selectedItems"
        v-stripped
        class="elevation-0"
        :headers="headers"
        :items="list"
        :server-items-length="pagination.total"
        :footer-props="{
          itemsPerPageOptions: viewConfig.per_page_options
        }"
        :items-per-page="pagination.perPage"
        :options.sync="options"
        :loading="fetchingData"
        :show-select="selectableItems"
        :class="{ 'row-pointer': rowPointer }"
        :sort-by.sync="options.sortBy"
        :sort-desc.sync="options.sortDesc"
        @click:row="showResource"
      >
        <!-- Header -->
        <template #top>
          <v-toolbar
            flat
            class="toolbar-container"
          >
            <div class="header-row">
              <v-toolbar-title class="toolbar-title" v-text="viewConfig.title" />
              <v-col>
                <v-btn
                  icon
                  @click="getItems(false, companyScopeId)"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </v-col>
              <v-col
                v-if="showCompanyScope"
                class="ml-5 company-scope"
                :xl="companyScopeColWidth('xl')"
                :lg="companyScopeColWidth('lg')"
                :md="companyScopeColWidth('md')"
                :sm="companyScopeColWidth('sm')"
              >
                <autocomplete
                  v-if="!isEmpty(viewConfig.change_company_scope)"
                  v-model="companyScopeId"
                  company-scope
                  :label="viewConfig.change_company_scope.label"
                  :hide-details="true"
                  :error-messages="validationErrors['companyScopeId']"
                  :clearable="true"
                  :items.sync="descendantCompanies"
                  :options="viewConfig.change_company_scope.autocomplete_options"
                  @input="clearInputValidationErrors('companyScopeId')"
                  @fetch="companyScopeResults = $event"
                />
              </v-col>

              <v-spacer />

              <v-col :cols="2">
                <v-select
                  v-if="selectOptionsPresent"
                  v-show="showImportSelect"
                  class="dense-select"
                  label=""
                  dense
                  outlined
                  hide-details
                  height="25"
                  :items="viewConfig.import.select_options"
                  :value="importSelectedOption"
                  @input="selectImportOption"
                  @blur="resetCustomImportValuesOnBlur"
                />
              </v-col>

              <div
                v-for="button in topButtons"
                :key="button.name"
              >
                <v-btn
                  v-if="button.visible"
                  :disabled="button.disabled"
                  rounded="rounded"
                  color="primary"
                  class="white--text px-3 mx-3"
                  @click="button.handler(this)"
                >
                  <v-icon
                    dense
                    class="mr-2"
                  >
                    {{ button.icon }}
                  </v-icon>
                  {{ button.name }}
                </v-btn>
              </div>

              <v-btn
                v-if="canSync"
                :disabled="syncing"
                rounded
                color="primary"
                class="white--text px-3 mr-3"
                @click.prevent="syncWithMasterApp(viewConfig.sync.route)"
                v-text="viewConfig.sync.button_text"
              />
              <v-btn
                v-if="canImport && showImportButton"
                :disabled="!canImport || !checkCompanyScopes"
                rounded
                color="primary"
                class="white--text px-5 mr-3"
                @click.prevent="showAppropriateImport(viewConfig.import)"
              >
                <v-icon
                  dense
                  class="mr-2"
                >
                  mdi-application-import
                </v-icon>
                {{ exportButtonText }}
              </v-btn>
              <v-btn
                v-if="viewConfig.export && canExport"
                :disabled="!canExport || !checkCompanyScopes"
                rounded
                color="primary"
                class="white--text px-5 mr-3"
                @click.prevent="handleExport"
              >
                <v-icon
                  dense
                  class="mr-2"
                >
                  mdi-application-export
                </v-icon>
                {{ viewConfig.export.button_text }}
              </v-btn>
              <v-btn
                v-if="!noCreate"
                v-show="!!viewConfig.create_button"
                :disabled="!canCreate || !checkCompanyScopes"
                rounded
                color="primary"
                class="white--text px-4 mr-3 pr-5"
                @click.prevent="handleCreateBtnClick"
              >
                <v-icon
                  class="mr-2"
                >
                  mdi-plus
                </v-icon>
                {{ viewConfig.create_button }}
              </v-btn>
            </div>
            <v-dialog
              v-model="createOrUpdateDialog"
              content-class="elevation-0"
              :width="vDialogSize[$vuetify.breakpoint.name]"
              persistent
              @keydown.enter.prevent="saveConfirmed"
              @keydown.esc.prevent="closeEditDialog"
            >
              <v-card class="form-fields-card">
                <v-row class="flex justify-space-between ma-0 light-grey">
                  <v-card-title>{{ dialogTitle }}</v-card-title>
                  <v-btn
                    text
                    x-small
                    class="mt-3 mr-2 pa-0 no-background-hover"
                    elevation="0"
                    @click="closeEditDialog"
                  >
                    <v-icon
                      color="grey darken-1"
                      size="1.8rem"
                    >
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-row>
                <div class="tabs-wrapper">
                  <template v-if="hasTabsConfig && !isSmallOrXSmallScreen">
                    <form @submit.prevent="saveConfirmed">
                      <v-tabs
                        v-model="currentTab"
                        vertical
                      >
                        <template
                          v-for="tab in viewConfig.tabs_config.tabs"
                        >
                          <v-tab
                            v-if="shouldShowTab(tab)"
                            :key="tab.key"
                            class="justify-start"
                          >
                            {{ tab.label }}
                            <v-icon
                              v-if="checkForValidationErrors(tab)"
                              class="ml-1 red-muted--text"
                            >
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-tab>

                          <v-tab-item
                            :key="tab.key + '_content'"
                            eager
                          >
                            <v-card
                              flat
                              class="px-4"
                            >
                              <v-card-text style="height: 70vh; overflow-y: scroll">
                                <form-fields
                                  :key="tab.key"
                                  :module="module"
                                  :model="model"
                                  :view-config="viewConfig"
                                  :form-type="formType"
                                  :data="formData"
                                  :container-dialog="createOrUpdateDialog"
                                  :fields="tab.fields"
                                  :edit-item="editItem"
                                  :validation-errors="validationErrors"
                                  :create-date-key="createDateKey"
                                  @update-data="updateTabFormData"
                                  @update-create-date="createDate = $event"
                                  @clear-errors="clearInputValidationErrors($event)"
                                />
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                        </template>
                      </v-tabs>
                      <v-divider />
                      <form-actions
                        :view-config="viewConfig"
                        :form-type="formType"
                        class="light-grey"
                        @close="closeEditDialog"
                      />
                    </form>
                  </template>

                  <v-form
                    v-else
                    @submit.prevent="saveConfirmed"
                  >
                    <v-card-text
                      v-if="viewConfig[formType]"
                      style="height: 70vh; overflow-y: scroll"
                    >
                      <form-fields
                        :module="module"
                        :view-config="viewConfig"
                        :form-type="formType"
                        :data="formData"
                        :container-dialog="createOrUpdateDialog"
                        :edit-item="editItem"
                        :model="model"
                        :validation-errors="validationErrors"
                        :create-date-key="createDateKey"
                        @update-data="formData = $event"
                        @update-create-date="createDate = $event"
                        @clear-errors="clearInputValidationErrors($event)"
                      />
                    </v-card-text>
                    <form-actions
                      :view-config="viewConfig"
                      :form-type="formType"
                      class="light-grey"
                      @close="closeEditDialog"
                    />
                  </v-form>
                </div>
              </v-card>
            </v-dialog>

            <v-dialog
              v-model="deleteDialog"
              content-class="elevation-0"
              :width="defaultDialogSize[$vuetify.breakpoint.name]"
              @keydown.enter.prevent="deleteConfirmed"
              @keydown.esc.prevent="closeDeleteDialog"
            >
              <v-card
                v-if="canDelete"
              >
                <v-row class="flex justify-space-between ma-0">
                  <v-card-title>{{ viewConfig.actions.delete.confirmation_title || '' }}</v-card-title>

                  <v-btn
                    text
                    x-small
                    class="mt-3 mr-2 pa-0 no-background-hover"
                    elevation="0"
                    @click="closeDeleteDialog"
                  >
                    <v-icon
                      color="grey darken-1"
                      size="1.8rem"
                    >
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-row>

                <v-card-text>{{ viewConfig.actions.delete.confirmation_message || '' }}</v-card-text>

                <v-card-actions>
                  <v-spacer />

                  <v-btn
                    color="grey darken-1"
                    text
                    @click="closeDeleteDialog"
                  >
                    {{ viewConfig.actions.delete.confirmation_cancel }}
                  </v-btn>

                  <v-btn
                    color="red"
                    text
                    @click="deleteConfirmed"
                  >
                    {{ viewConfig.actions.delete.confirmation_confirm || '' }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              v-model="changeStatusDialog"
              content-class="elevation-0"
              :width="defaultDialogSize[$vuetify.breakpoint.name]"
              @keydown.enter.prevent="changeStatusConfirmed"
              @keydown.esc.prevent="closeChangeStatusDialog"
            >
              <v-card
                v-if="canActivate || canDeactivate"
                @click:outside="closeChangeStatusDialog"
              >
                <v-row class="flex justify-space-between ma-0">
                  <v-card-title>
                    {{
                      selectedItemActive ?
                        (viewConfig.actions.deactivate.confirmation_confirm || '') :
                        (viewConfig.actions.activate.confirmation_confirm || '')
                    }}
                  </v-card-title>

                  <v-btn
                    text
                    x-small
                    class="mt-3 mr-2 pa-0 no-background-hover"
                    elevation="0"
                    @click="closeChangeStatusDialog"
                  >
                    <v-icon
                      color="grey darken-1"
                      size="1.8rem"
                    >
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-row>

                <v-card-text>
                  {{
                    selectedItemActive ?
                      (viewConfig.actions.deactivate.confirmation_message || '') :
                      (viewConfig.actions.activate.confirmation_message || '')
                  }}
                </v-card-text>

                <v-card-actions>
                  <v-spacer />

                  <v-btn
                    color="grey darken-1"
                    text
                    @click="closeChangeStatusDialog"
                  >
                    {{ viewConfig.actions.deactivate.confirmation_cancel || '' }}
                  </v-btn>

                  <v-btn
                    color="red"
                    text
                    @click="changeStatusConfirmed"
                  >
                    {{
                      selectedItemActive ?
                        (viewConfig.actions.deactivate.confirmation_confirm || '') :
                        (viewConfig.actions.activate.confirmation_confirm || '')
                    }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              v-model="importDialogOpen"
              content-class="elevation-0"
              persistent
              :width="defaultDialogSize[$vuetify.breakpoint.name]"
              @keydown.enter.prevent="importConfirmed"
              @keydown.esc.prevent="closeImportDialog"
            >
              <v-card
                v-if="canImport"
                @click:outside="closeImportDialog"
              >
                <v-row class="flex justify-space-between ma-0">
                  <v-card-title>
                    {{ importTitle }}
                  </v-card-title>

                  <v-btn
                    text
                    x-small
                    class="mt-3 mr-2 pa-0 no-background-hover"
                    elevation="0"
                    @click="closeImportDialog"
                  >
                    <v-icon
                      color="grey darken-1"
                      size="1.8rem"
                    >
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-row>

                <v-card-text>
                  <v-file-input
                    v-model="importFile"
                    clearable
                    :label="$t('base.import_template_label')"
                    :error-count="10"
                    :error-messages="importValidationErrors['file'] || importValidationErrors['vehicles']"
                    :accept="importFileFormat"
                    @change="importValidationErrors['file'] = []"
                  />
                  <v-btn
                    v-show="showDownloadTemplateButton"
                    color="primary"
                    class="mt-2"
                    @click="downloadImportTemplate"
                    v-text="importDownloadButtonText"
                  />
                </v-card-text>

                <v-card-actions>
                  <v-spacer />

                  <v-btn
                    color="grey darken-1"
                    text
                    @click="closeImportDialog"
                    v-text="importCancelButtonText"
                  />

                  <v-btn
                    color="primary"
                    text
                    @click="importConfirmed"
                    v-text="importButtonText"
                  />
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              v-if="viewConfig.actions && viewConfig.actions.reset_password"
              v-model="isResetPasswordDialogOpen"
              content-class="elevation-0"
              :width="defaultDialogSize[$vuetify.breakpoint.name]"
              @keydown.enter.prevent="resetPasswordConfirmed"
              @keydown.esc.prevent="closeResetPasswordDialog"
            >
              <v-card
                v-if="canResetPassword"
              >
                <v-row class="flex justify-space-between ma-0">
                  <v-card-title>{{ viewConfig.actions.reset_password.title || '' }}</v-card-title>

                  <v-btn
                    text
                    x-small
                    class="mt-3 mr-2 pa-0 no-background-hover"
                    elevation="0"
                    @click="closeResetPasswordDialog"
                  >
                    <v-icon
                      color="grey darken-1"
                      size="1.8rem"
                    >
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-row>

                <v-card-text>{{ viewConfig.actions.reset_password.confirmation_message || '' }}</v-card-text>

                <v-card-actions>
                  <v-spacer />

                  <v-btn
                    color="grey darken-1"
                    text
                    @click="closeResetPasswordDialog"
                  >
                    {{ viewConfig.actions.reset_password.confirmation_cancel }}
                  </v-btn>

                  <v-btn
                    color="red"
                    text
                    @click="resetPasswordConfirmed"
                  >
                    {{ viewConfig.actions.reset_password.confirmation_confirm || '' }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <change-company-dialog
              :view-config="viewConfig"
              :change-company-model="changeCompanyModel"
              :open-change-company-dialog="openChangeCompanyDialog"
              @exception="exceptionData => showChangeDialogException(exceptionData)"
              @close-dialog="closeChangeCompanyDialog"
            />
            <LocationMapModal
              :geolocation="locationCoordinates"
              :vehicle-id="vehicleId"
              :location-dialog="openLocationMapModal"
              :dialog-title="$t('locations.location')"
              @close-dialog="openLocationMapModal = false"
            />
          </v-toolbar>

<!--          New filters-->
          <v-toolbar
            flat
            dense
          >
            <v-btn
              icon
              @click="showFiltersDialog = true"
            >
              <v-icon
                color="#869DB6"
              >
                mdi-filter-variant
              </v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider />
        </template>

        <!-- Datatable cells -->
        <template
          v-for="(data, column) in viewConfig.columns"
          #[getItemSlot(column)]="{ item }"
        >
          <div
            v-if="viewConfig.columns[column].data_type === 'boolean'"
            :key="column"
          >
            <v-icon
              v-if="checkBooleanColumn(item, column)"
              size="23px"
              color="success"
              class="py-3"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              v-else
              size="23px"
              color="red"
              class="py-3"
            >
              mdi-close-circle
            </v-icon>
          </div>
          <div
            v-else-if="viewConfig.columns[column].data_type === 'color'"
            :key="column"
            class="table-data-preview"
            :style="{ backgroundColor: item[column] }"
          />
          <v-tooltip
            v-else-if="viewConfig.columns[column].type === 'icon_tooltip' || !!viewConfig.columns[column].show_icon"
            :key="column"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-icon
                :disabled="!checkCompanyScopes"
                size="23px"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                {{ viewConfig.columns[column].icon }}
              </v-icon>
            </template>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="item[column] || getCellDisplayValue(item, column)" />
          </v-tooltip>
          <div
            v-else-if="viewConfig.columns[column].data_type === 'icon'"
            :key="column"
          >
            <icon-field
              :item="item"
              :config="viewConfig.columns[column]"
              @open-map-overview="openMapOverviewDialog"
            />
          </div>
          <div
            v-else-if="viewConfig.columns[column].data_type === 'icon_image'"
            :key="column"
          >
            <icon-image-field
              :item="item"
              :config="viewConfig.columns[column]"
            />
          </div>
          <div
            v-else-if="viewConfig.columns[column].data_type === 'icon_toggle'"
            :key="column"
          >
            <icon-toggle-field
              :item="item"
              :name="column"
              :config="viewConfig.columns[column]"
            />
          </div>
          <div
            v-else-if="viewConfig.columns[column].data_type === 'text_colored'"
            :key="column"
            :style="{color: item[viewConfig.columns[column].color_field]}"
          >
            {{ getCellDisplayValue(item, column) }}
          </div>
          <div
            v-else-if="showAlarmEventLocation(item, column)"
            :key="column"
          >
            <v-icon
              class="map-location"
              @click="openAlarmEventLocation(item)"
            >
              mdi-map-marker-radius
            </v-icon>
          </div>
          <span
            v-else
            :key="column"
          >
            {{ getCellDisplayValue(item, column) }}
          </span>
        </template>

        <template #[`item.active`]="{ item }">
          <v-icon
            v-if="itemIsDeactivated(item)"
            size="23px"
            color="success"
            class="py-3"
          >
            mdi-check-circle
          </v-icon>
          <v-icon
            v-else
            size="23px"
            color="red"
            class="py-3"
          >
            mdi-close-circle
          </v-icon>
        </template>

        <template
          #[`item.actions`]="{ item }"
        >
          <v-menu
            offset-y
            offset-overflow
            left
          >
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="canSeeActions(item)"
                :disabled="!checkCompanyScopes"
                v-bind="attrs"
                v-on="on"
              >
                mdi-dots-vertical
              </v-icon>
            </template>

            <v-list class="pa-0">
              <v-list-item
                class="d-flex align-center list-item-min-height"
                v-if="canBeEdited(item)"
                link
                @click="openEditDialog(item)"
              >
                <v-list-item-icon class="align-self-center my-0 mr-2">
                  <v-icon small>
                    {{ viewConfig.actions.edit.icon || '' }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title class="main-font-size">
                  {{ viewConfig.actions.edit.text || '' }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                class="d-flex align-center list-item-min-height"
                v-if="canBeActivatedOrDeactivated(item)"
                link
                @click="openChangeStatusDialog(item)"
              >
                <v-list-item-icon class="align-self-center my-0 mr-2">
                  <v-icon
                    v-if="!item.deactivated_at"
                    small
                  >
                    {{ viewConfig.actions.deactivate.icon || '' }}
                  </v-icon>
                  <v-icon
                    v-else
                    small
                  >
                    {{ viewConfig.actions.activate.icon || '' }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title class="main-font-size">
                  {{ item.deactivated_at ?
                  (viewConfig.actions.activate.text || '' ) :
                  (viewConfig.actions.deactivate.text || '' ) }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                class="d-flex align-center list-item-min-height"
                v-if="canResetPassword(item)"
                link
                @click="openResetPasswordDialog(item)"
              >
                <v-list-item-icon class="align-self-center my-0 mr-2">
                  <v-icon small>
                    {{ viewConfig.actions.reset_password.icon || '' }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title class="main-font-size">
                  {{ viewConfig.actions.reset_password.text || '' }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                class="d-flex align-center list-item-min-height"
                v-if="canBeDeleted(item)"
                link
                @click="openDeleteDialog(item)"
              >
                <v-list-item-icon class="align-self-center my-0 mr-2">
                  <v-icon small>
                    {{ viewConfig.actions.delete.icon || '' }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title class="main-font-size">
                  {{ viewConfig.actions.delete.text || '' }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                class="d-flex align-center list-item-min-height"
                v-if="canChangeCompany(item)"
                link
                @click="openChangeModelCompanyDialog(item)"
              >
                <v-list-item-icon class="align-self-center my-0 mr-2">
                  <v-icon small>
                    {{ viewConfig.actions.change_company.icon || '' }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title class="main-font-size">
                  {{ viewConfig.actions.change_company.text || '' }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <!-- Summary row for displaying total sum of values for specified columns -->
        <template
          v-if="Object.keys(summary).length > 0"
          slot="body.append"
        >
          <tr>
            <td
              v-for="{ sum, column } in summaryColumns"
              :key="sum + '-' + column"
              class="summary-cell text-no-wrap"
              v-html="formatSumCellContent(sum)"
            />
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <exception-dialog
      :error-title="exceptionTitle"
      :error-message="exceptionMessage"
      :exception-dialog="exceptionDialog"
      @close-exception-dialog="closeExceptionDialog"
    />
    <table-filters-dialog
      v-if="showFiltersDialog"
      :show-dialog="showFiltersDialog"
      :dialog-title="filtersDialogConfig.title"
      :config="filtersDialogConfig.config"
      :filters="filtersDialogConfig.filters"
      @close="closeFiltersDialog"
    />
    <v-overlay
      :value="importOrExportLoading || syncing || showLoader"
      z-index="1000"
    >
      <v-progress-circular
        indeterminate
        size="60"
      />
    </v-overlay>
  </v-container>
</template>

<script>
import LocationMapModal from '@/global/components/modals/LocationMapModal'
import Autocomplete from '@/modules/base-module/autocomplete/components/Autocomplete'
import ExceptionDialog from '@/global/components/ExceptionDialog'
import ChangeCompanyDialog from '@/modules/base-module/company/components/ChangeCompanyDialog'
import FormFields from '@/global/components/form-fields/FormFields'
import FormActions from '@/global/components/FormActions'
import IconField from '@/global/components/IconField'
import IconImageField from '@/global/components/IconImageField'
import IconToggleField from '@/global/components/IconToggleField'
import {
  forEach,
  get,
  has,
  isEmpty,
  isEqual,
  pickBy,
  toLower
} from 'lodash'
import dayjs from 'dayjs'
import { api } from '@/global/services/api'
import {
  formatSqlDate,
  formatSqlDateTime,
  parseDmyDate
} from '@/global/services/helpers/dates'
import indexMethods from '@/global/mixins/indexMethods'
import { createNamespacedHelpers } from 'vuex'
import TableFiltersDialog from '@/global/components/view-layouts/tabs-layout/components/dialogs/TableFiltersDialog.vue'

const {
  mapGetters: mapGettersConfig,
  mapActions: mapActionsConfig
} = createNamespacedHelpers('base/config')
const {
  mapGetters: mapGettersFormFields,
  mapActions: mapActionsFormFields
} = createNamespacedHelpers('form-fields')
const {
  mapActions: mapActionsDependantAutocompletes
} = createNamespacedHelpers('dependant-autocompletes')

export default {
  components: {
    TableFiltersDialog,
    Autocomplete,
    ChangeCompanyDialog,
    ExceptionDialog,
    FormActions,
    FormFields,
    IconField,
    IconImageField,
    IconToggleField,
    LocationMapModal
  },

  mixins: [indexMethods],

  props: {
    module: {
      type: String,
      default: 'base',
      required: true
    },
    model: {
      type: String,
      required: true
    },
    showResourceRouteName: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      required: true
    },
    sort: {
      type: Object,
      default: () => ({})
    },
    pagination: {
      type: Object,
      required: true
    },
    initialFilters: {
      type: Object,
      default: null
    },
    filtersCache: {
      type: Object,
      default: null
    },
    setFiltersCacheKey: {
      type: Function,
      default: null
    },
    resetFiltersCache: {
      type: Function,
      default: null
    },
    viewConfig: {
      type: Object,
      required: true
    },
    topButtons: {
      type: Array,
      default: function () {
        return []
      }
    },
    showLoader: {
      type: Boolean,
      default: false
    },
    summary: {
      type: Object,
      default: () => ({})
    },
    viewName: {
      type: String,
      required: true
    },
    fetch: {
      type: Function,
      required: true
    },
    fetchConfig: {
      type: Function,
      default: null
    },
    selectableItems: {
      type: Boolean,
      default: false
    },
    resetSelection: {
      type: Boolean,
      default: false
    },
    resetForm: {
      type: Boolean,
      default: false
    },
    rowPointer: {
      type: Boolean,
      default: false
    },
    noCreate: {
      type: Boolean,
      default: false
    },
    noActions: {
      type: Boolean,
      default: false
    },
    showEditDialog: {
      type: Boolean,
      default: false
    },
    preventDefaultCreate: {
      type: Boolean,
      default: false
    },
    preventDefaultEdit: {
      type: Boolean,
      default: false
    },
    showOverlay: {
      type: Boolean,
      default: false
    },
    additionalFilters: {
      type: Object,
      default: () => {}
    },
    additionalFormData: {
      type: Object,
      default: () => {}
    },
    vDialogSize: {
      type: Object,
      default: () => ({ xl: '55%', lg: '65%', md: '90%', sm: '100%', xs: '100%' }),
      validator: (attributes) => {
        const neededKeysArray = ['xl', 'lg', 'md', 'sm', 'xs']
        const neededKeysArePresent = isEqual(Object.keys(attributes), neededKeysArray)

        const sizes = {}
        for (const [key, value] of Object.entries(attributes)) {
          sizes[key] = value
        }
        const vColSizesAreValid = Object.values(sizes)
          .every(size => ['55%', '65%', '90%', '100%'].indexOf(size) !== -1)

        return neededKeysArePresent && vColSizesAreValid
      }
    },
    containerClasses: {
      type: Array,
      default: () => ([])
    },
    containerStyles: {
      type: Object,
      default: () => ({})
    },
    showFilters: {
      type: Boolean,
      default: false
    },
    filtersDialogConfig: {
      type: Object,
      default: () => ({
        title: '',
        config: {},
        filters: {}
      })
    }
  },
  data () {
    return {
      syncing: false,
      filters: {},
      columns: {},
      selectedItems: [],
      createOrUpdateDialog: false,
      saving: false,
      exceptionDialog: false,
      exceptionTitle: '',
      exceptionMessage: '',
      createDate: null,
      createDateKey: null,
      createFormattedDate: null,
      editItem: null,
      changeCompanyModel: {},
      openChangeCompanyDialog: false,
      openLocationMapModal: false,
      locationCoordinates: null,
      vehicleId: null,
      resetPasswordId: -1,
      isResetPasswordDialogOpen: false,
      importDialogOpen: false,
      formData: {},
      activeColumns: [],
      oldActiveColumns: [],
      deleteDialog: false,
      deleteId: -1,
      options: {
        page: 1,
        itemsPerPage: null,
        sortBy: [],
        sortDesc: []
      },
      itemsInitiallyLoad: false,
      activeFilters: {},
      changeStatusDialog: false,
      changeStatusId: -1,
      selectedItemActive: false,
      validationErrors: {},
      fetchingData: false,
      fetchingConfig: false,
      statusChangeLoading: [],
      dynamicFields: {},
      descendantCompanies: [],
      companyScopeId: null,
      companyScopeMode: false,
      companyScopeResults: [],
      importFile: null,
      importValidationErrors: {},
      importOrExportLoading: false,
      showImportSelect: false,
      importSelectedOption: null,
      importSelectedOptionFormatted: null,
      mapOverviewDialogOpen: false,
      mapOverviewData: {},
      currentTab: '',
      defaultDialogSize: { xl: '55%', lg: '55%', md: '50%', sm: '66.66%', xs: '100%' },
      showFiltersDialog: false
    }
  },
  computed: {
    ...mapGettersConfig({
      globalCompanyScope: 'companyScope',
      isCompanyScopeUpdated: 'isCompanyScopeUpdated'
    }),
    ...mapGettersFormFields(['dynamicArrays']),

    ...mapGettersConfig(['isMaster']),

    isMasterUser () {
      return this.isMaster
    },

    canCreate () {
      if ('actions' in this.viewConfig) {
        return 'create' in this.viewConfig.actions
      }

      return false
    },

    canEdit () {
      if ('actions' in this.viewConfig) {
        return 'edit' in this.viewConfig.actions
      }

      return false
    },

    canActivate () {
      if ('actions' in this.viewConfig) {
        return 'activate' in this.viewConfig.actions
      }

      return false
    },

    canDeactivate () {
      if ('actions' in this.viewConfig) {
        return 'deactivate' in this.viewConfig.actions
      }

      return false
    },

    canDelete () {
      if ('actions' in this.viewConfig) {
        return 'delete' in this.viewConfig.actions
      }

      return false
    },

    canRestore () {
      if ('actions' in this.viewConfig) {
        return 'restore' in this.viewConfig.actions
      }

      return false
    },

    canSync () {
      if ('sync' in this.viewConfig) {
        return Object.keys(this.viewConfig.sync).length > 0
      }

      return false
    },

    canImport () {
      if ('import' in this.viewConfig) {
        return Object.keys(this.viewConfig.import).length > 0
      }

      return false
    },

    canExport () {
      if ('export' in this.viewConfig) {
        return Object.keys(this.viewConfig.export).length > 0
      }

      return false
    },

    checkCompanyScopes () {
      if (this.globalCompanyScope) {
        return true
      }

      if (this.companyScopeMode) {
        return false
      }

      return true
    },

    showCompanyScope () {
      if (this.globalCompanyScope) {
        return false
      }

      return true
    },

    editInProgress () {
      return this.editItem !== null
    },

    formType () {
      return this.editInProgress ? 'edit_form' : 'create_form'
    },

    dialogTitle () {
      let title = ''
      if (this.editInProgress) {
        title = this.viewConfig.edit_form?.title || ''
      }
      else {
        title = this.viewConfig.create_form?.title || ''
      }

      return title
    },

    visibleHeaders () {
      return this.headers.filter(column => this.activeColumns.indexOf(column.value) !== -1)
    },

    isMultiImport () {
      return this.viewConfig.import.type === 'multi'
    },

    showImportButton () {
      if (this.isMultiImport) {
        return this.viewConfig.import.common_config && !this.showImportSelect
      }

      return !isEmpty(this.viewConfig.import)
    },

    importTitle () {
      if (this.isMultiImport) {
        if (this.showImportSelect) {
          return this.viewConfig.import.config_map[this.importSelectedOptionFormatted]?.title
        }

        return this.viewConfig.import.config_map.ecomobile.title
      }

      return this.viewConfig.import.title
    },

    importFileFormat () {
      const excelFileFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
      if (this.importSelectedOptionFormatted === 'ina_card_(xml)') {
        return 'text/xml'
      }

      return excelFileFormat
    },

    showDownloadTemplateButton () {
      if (this.isMultiImport) {
        return this.importSelectedOptionFormatted
          ? this.viewConfig.import.config_map[this.importSelectedOptionFormatted] &&
          'template_route' in this.viewConfig.import.config_map[this.importSelectedOptionFormatted]
          : 'template_route' in this.viewConfig.import.config_map.ecomobile
      }

      return true
    },

    importDownloadButtonText () {
      if (this.isMultiImport) {
        return this.viewConfig.import.common_config.template_button
      }

      return this.viewConfig.import.template_button
    },

    importCancelButtonText () {
      if (this.isMultiImport) {
        return this.viewConfig.import.common_config.button_cancel
      }

      return this.viewConfig.import.button_cancel
    },

    importButtonText () {
      if (this.isMultiImport) {
        return this.viewConfig.import.common_config.button_import
      }

      return this.viewConfig.import.button_import
    },

    exportButtonText () {
      if (this.isMultiImport) {
        return this.viewConfig.import.common_config.multi_button_text
      }

      return this.viewConfig.import.button_text
    },

    summaryColumns () {
      return Object.values(this.visibleHeaders).map(item => {
        return {
          column: item.value,
          sum: this.summary[item.value]
        }
      })
    },

    filteredOptions () {
      return {
        itemsPerPage: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0] ? this.options.sortBy[0] : null,
        sortDesc: this.options.sortDesc[0] ? this.options.sortDesc[0] : null
      }
    },

    hasTabsConfig () {
      return this.viewConfig.tabs_config?.tabs?.length
    },

    selectOptionsPresent () {
      return !!this.viewConfig.import?.select_options?.length
    },

    getImportRoute () {
      let route

      if (this.isMultiImport) {
        if (this.showImportSelect) {
          route = this.viewConfig.import.config_map[this.importSelectedOptionFormatted]?.route
        }
        else {
          route = this.viewConfig.import.config_map.ecomobile.route
        }
      }
      else {
        route = this.viewConfig.import.route
      }

      return route
    },

    isSmallOrXSmallScreen () {
      return this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs'
    },

    formDefaults () {
      const data = {}

      forEach(this.viewConfig.create_form?.form, function (object, key) {
        data[key] = object.type === 'dynamic_input_select' ? [] : null
      })

      return data
    },

    headers () {
      const data = []

      if ('columns' in this.viewConfig) {
        forEach(this.viewConfig.columns, function (column, key) {
          if ('show_in_table' in column && !column.show_in_table) return

          data.push({
            text: column.label,
            value: key,
            sortable: column.sortable,
            align: column.alignment ?? 'left',
            class: 'custom-data-table-header'
          })
        })
      }

      /* TODO: Use action from viewConfig  : maybe */
      if (!this.noActions) {
        data.push(
          {
            text: this.$t('base.actions'),
            value: 'actions',
            width: '5%',
            align: 'center',
            class: 'custom-data-table-header',
            sortable: false
          }
        )
      }

      return data
    },

    setFilters () {
      const data = {}
      if ('filters' in this.viewConfig && 'filters' in this.viewConfig.filters) {
        forEach(this.viewConfig.filters.filters, function (filter, key) {
          data[key] = {
            ...(filter.label && { label: filter.label }),
            type: filter.type
          }

          if ('selectionList' in filter) {
            data[key].selectionList = []
            forEach(filter.selectionList, function (text, value) {
              data[key].selectionList.push({
                text: text,
                value: value
              })
            })
          }

          if ('autocomplete_options' in filter) {
            data[key].options = filter.autocomplete_options
          }

          if ('dependants' in filter) {
            data[key].dependants = filter.dependants
          }
        })
      }

      return data
    },

    setColumns () {
      const data = {}

      if ('columns' in this.viewConfig) {
        forEach(this.viewConfig.columns, function (column, key) {
          if (!column.dataTable || column.show_in_table === false) return

          data[key] = {
            label: column.label
          }

          if ('always_visible' in column) {
            data[key].alwaysVisible = column.always_visible
          }
          if ('initially_visible' in column) {
            data[key].initiallyVisible = column.initially_visible
          }
        })

        if (!this.noActions) {
          data.actions = {
            alwaysVisible: true,
            label: this.$t('base.actions')
          }
        }
      }

      return data
    },

    sortOptions () {
      return {
        by: this.filteredOptions.sortBy,
        desc: this.filteredOptions.sortDesc,
        ...this.options.sortReset && { reset: this.options.sortReset }
      }
    }
  },

  watch: {
    initialFilters: {
      immediate: true,
      deep: true,
      handler (initialFilters) {
        this.setInitialFilters(initialFilters)
      }
    },

    pagination: {
      deep: true,
      handler (value) {
        this.options.itemsPerPage = value.perPage
      }
    },

    sort (sort) {
      if (!isEmpty(sort)) {
        this.options.sortBy[0] = sort.by
        this.options.sortDesc[0] = sort.desc
      }
    },

    options: {
      deep: true,
      handler (options, oldOptions) {
        // If user clicked third time on the same column to reset it then define `sortReset`
        // field in the options object.
        if (!options.sortBy.length && oldOptions.sortBy.length) {
          options.sortReset = oldOptions.sortBy[0]
        }
      }
    },

    deleteDialog (newVal) {
      !newVal && (this.deleteId = -1)
    },

    setFilters (filters) {
      // active_filters is 1 for locations, because we always filter them by type from url path
      if (
        this.viewConfig.active_filters === 0 ||
        (this.viewConfig.active_filters === 1 && this.viewConfig.routeString === 'locations')
      ) {
        this.filters = filters
      }
    },

    setColumns: {
      immediate: true,
      handler (columns) {
        this.columns = columns
      }
    },

    list (listItems) {
      // Need to set this here because `pagination` watcher does not trigger or sequential page visits
      // and `list` watcher is always triggered. Only update if items per page is set to `null`.
      // Without this, prev and next pagination buttons will be disabled, and no total items count
      // and current page offset will be shown in the datatable (example: 1-10 od 23)
      if (!this.options.itemsPerPage) {
        this.options.itemsPerPage = this.pagination.perPage
      }

      const createForm = this.viewConfig?.create_form?.form

      if (!createForm) return

      const datePickerColumns = []
      for (const [key, value] of Object.entries(createForm)) {
        if (value.type === 'datepicker') {
          datePickerColumns.push(key)
          this.createDateKey = value.field
        }
      }

      listItems.map(item => {
        datePickerColumns.forEach(column => {
          item[`formatted_${column}`] = formatSqlDate(item[column])

          return item
        })
      })
    },

    filteredOptions: {
      deep: true,
      handler () {
        if (this.showFilters && this.fetchConfig) {
          this.fetchConfig()
        }
        else {
          // Only get items after initial load, to avoid duplicate api calls
          // Initial load will happen when component is first created
          if (this.itemsInitiallyLoad) {
            this.getItems(false, this.companyScopeId)
          }
        }
      }
    },

    async companyScopeId (newValue) {
      this.companyScopeMode = !!newValue
      await this.getItems(false, newValue)
    },

    resetSelection (value) {
      if (value) {
        this.selectedItems = []
      }
    },

    resetForm (value) {
      if (value) {
        this.closeEditDialog()
      }
    },

    selectedItems (newValue) {
      this.$emit('selection-updated', newValue)
    },

    showEditDialog (value) {
      this.createOrUpdateDialog = !!value
    },

    additionalFilters: {
      deep: true,
      handler () {
        this.getItems(false, this.companyScopeId)
      }
    },

    additionalFormData: {
      deep: true,
      handler (value) {
        if ('data_type_id' in value) {
          this.formData.data_type_id = value.data_type_id
        }
      }
    },

    createDate (date) {
      this.createFormattedDate = formatSqlDate(date)
    },

    formData: {
      deep: true,
      handler (formData) {
        const formattedPrefix = 'formatted_'
        const formattedDateColumns = Object.keys(formData).filter(columnKey => columnKey.startsWith(formattedPrefix))
        formattedDateColumns.forEach(formattedDateColumn => {
          const defaultDateColumn = formattedDateColumn.replace(formattedPrefix, '')
          this.formData[formattedDateColumn] = formatSqlDate(formData[defaultDateColumn])
        })
      }
    },

    isCompanyScopeUpdated (value) {
      if (value) {
        this.companyScopeId = null
        this.companyScopeMode = false
        this.getItems(true, null)
        this.unsetCompanyScopeUpdated()
      }
    },

    validationErrors: {
      deep: true,
      handler (value) {
        if (this.hasTabsConfig) {
          let firstErrorField = Object.keys(value)[0]
          firstErrorField = firstErrorField ? firstErrorField.split('.')[0] : firstErrorField
          this.currentTab = this.viewConfig.tabs_config.tabs.findIndex((tab) => {
            if (tab.fields.includes(firstErrorField)) return tab
          })
        }
      }
    }
  },

  async created () {
    this.resetData('filters')
    this.$emit('reset-initial-filter-loaded-keys')

    if (this.fetchConfig) {
      try {
        this.fetchingConfig = true
        await this.fetchConfig()
      }
      finally {
        this.fetchingConfig = false
      }
    }

    this.setPreviouslyVisitedStorePagination()
    await this.getItems(false, this.companyScopeId)
  },

  methods: {
    ...mapActionsConfig([
      'unsetCompanyScopeUpdated',
      'updateDigitalSensorsConfig'
    ]),
    ...mapActionsFormFields(['resetDynamicArrays']),
    ...mapActionsDependantAutocompletes(['resetData']),

    /* ...this.actions(['fetch']), */
    has,

    setInitialFilters (initialFilters) {
      for (const key in initialFilters) {
        const initialFilter = initialFilters[key]

        if (!isEmpty(initialFilter) && !initialFilter.loaded) {
          const value = { ...initialFilter, loaded: true }
          this.$nextTick(() => (this.$emit('set-initial-filter', { key, value })))
        }
      }
    },

    setPreviouslyVisitedStorePagination () {
      this.options.page = this.pagination.currentPage
    },

    async syncWithMasterApp ($syncUrl) {
      this.syncing = true
      await api().base.post($syncUrl)
      this.syncing = false
    },

    async closeFiltersDialog (fromApply = false) {
      this.showFiltersDialog = false

      if (fromApply) {
        this.activeFilters = this.filtersDialogConfig.filters
        await this.getItems(true)
      }
    },

    checkForValidationErrors (tab) {
      const validationErrorKeysString = Object.keys(this.validationErrors).toString()
      return tab.fields.some((fieldName) => {
        return validationErrorKeysString.indexOf(fieldName) !== -1
      })
      // tab in viewConfig.tabs_config.tabs
    },

    canSeeActions (item) {
      if ('actions' in item) {
        return item.actions.length
      }

      return this.canEdit || this.canActivate || this.canDeactivate || this.canDelete || this.canRestore
    },

    canChangeCompany (item) {
      return 'actions' in item && item.actions.includes('change-company')
    },

    canUpdateFuelProbe (item, number) {
      const actionName = `update-fuel-probe-${number}`
      if ('actions' in item && item.actions.includes(actionName)) {
        const action = actionName.replaceAll('-', '_')
        if ('show_depending_on_resource_field' in this.viewConfig.actions[action] || {}) {
          return !!item[this.viewConfig.actions[action].show_depending_on_resource_field]
        }
        return true
      }

      return false
    },

    canResetPassword (item) {
      return 'actions' in item && item.actions.includes('reset_password')
    },

    canBeEdited (item) {
      if ('actions' in item) {
        return this.canEdit && item.actions.includes('edit')
      }

      return this.canEdit
    },

    canBeActivatedOrDeactivated (item) {
      const canActivate = item.deactivated_at && this.canActivate
      const canDeactivate = !item.deactivated_at && this.canDeactivate
      if ('actions' in item) {
        return (canDeactivate && item.actions.includes('deactivate')) ||
          (canActivate && item.actions.includes('activate'))
      }

      return canDeactivate || canActivate
    },

    canBeDeleted (item) {
      if ('actions' in item) {
        return this.canDelete && item.actions.includes('delete')
      }

      return this.canDelete
    },

    checkBooleanColumn (item, column) {
      return this.viewConfig.columns[column].field?.includes('.')
        ? !!get(item, this.viewConfig.columns[column].nested_value)
        : !!item[column]
    },

    shouldShowTab (tab) {
      if (!this.editInProgress && 'creatable' in tab) {
        return tab.creatable
      }
      if (this.editInProgress && 'show_depending_on_resource_field' in tab) {
        return !!this.editItem[tab.show_depending_on_resource_field]
      }

      return true
    },

    formatSumCellContent (sum) {
      if (typeof sum === 'object') {
        return Object.entries(sum)
          .map(([key, value]) => `${key}: ${value}`)
          .join('<br>')
      }

      return sum
    },

    getItemSlot (column) {
      return 'item.' + column
    },

    getCellDisplayValue (item, column) {
      const value = item[column]

      const columns = this.viewConfig.columns

      const {
        data_type: dataType,
        type,
        selected,
        autocomplete_options: autocompleteOptions
      } = columns[column]

      if (value === null && type !== 'dynamic') return ''

      if (dataType === 'datetime') {
        return formatSqlDateTime(value)
      }

      if (dataType === 'array') {
        const relationKey = autocompleteOptions[0].model

        return this.formatMultiautocompleteResourceDisplayField(
          item[relationKey], selected, autocompleteOptions
        )
      }

      if (dataType === 'json') {
        const availableValues = columns[column].selectionList

        if (!availableValues || (!value && !('nested_value' in columns[column]))) return ''

        // if `join_delimiter` is defined in columns config then use it,
        // otherwise use default delimiter (`, `)
        const joinDelimiter = 'join_delimiter' in columns[column] ? columns[column].join_delimiter : ', '
        // This was added to cover the case when reaching for data that is not directly available
        // in the resource object but on the relation/relations.
        if ('nested_value' in columns[column]) {
          const objPropertiesArray = columns[column].nested_value?.split('.')
          const jsonValue = objPropertiesArray?.reduce((acc, property) => acc[property], { ...item })

          return jsonValue.map(item => availableValues[item]).join(joinDelimiter)
        }

        return value.map(item => availableValues[item]).join(joinDelimiter)
      }

      if (dataType === 'many2many') {
        return value.map(item => item[columns[column].default_column]).join(', ')
      }

      if (type === 'input_location_picker') {
        return `${value.lat}, ${value.lng}`
      }

      if (dataType === 'text' && (type === 'datepicker' || type === 'datepicker_range')) {
        return formatSqlDate(item[columns[column].field])
      }

      if (type === 'dynamic') {
        return item[columns[column].field]
      }

      if (typeof value === 'object') {
        const defaultColumn = columns[column].default_column

        return defaultColumn ? value[defaultColumn] : value.name
      }

      if (!has(item, column)) {
        // If column has `nested_value` defined in the config, it represents the relation
        // with the final value that will be reached inside an object.
        if ('nested_value' in columns[column]) {
          const objPropertiesArray = columns[column].nested_value?.split('.')
          const nullableRelation = 'nullable' in columns[column] && columns[column].nullable
          return this.getNestedValue(objPropertiesArray, nullableRelation, item)
        }
        // If column has `nested_values` key that is array of multiple relations,
        // then first value that is not null will be returned. Because of this oreding of the
        // relations in the column config is important.
        else if ('nested_values' in columns[column]) {
          for (const nestedValueSettings of columns[column].nested_values) {
            const objPropertiesArray = nestedValueSettings.nested_value?.split('.')
            const nullableRelation = 'nullable' in nestedValueSettings && nestedValueSettings.nullable
            const value = this.getNestedValue(objPropertiesArray, nullableRelation, item)
            if (value) return value
          }
        }
      }

      return value
    },

    /**
     * It reaches for each object key that is contained in the `objPropertiesArray`.
     * At the end the last value is returned.
     * @param objPropertiesArray
     * @param nullableRelation
     * @param item
     * @returns {*}
     */
    getNestedValue (objPropertiesArray, nullableRelation, item) {
      return objPropertiesArray?.reduce((acc, property) => {
        // if `nullableRelation` is set to `true`, empty string will be returned if the value is not present
        if (nullableRelation && !acc[property]) return ''
        return acc[property]
      }, { ...item })
    },

    itemIsDeactivated (item) {
      return item.deactivated_at == null
    },

    updateTabFormData (data) {
      this.formData = data
    },

    async getItems (resetPage = false, companyScopeId = null) {
      if (resetPage) {
        this.options.page = 1
      }
      const options = this.filteredOptions
      const activeFilters = pickBy(this.activeFilters, (filter) => filter !== null)
      const params = {
        pagination: {
          perPage: options.itemsPerPage ?? this.viewConfig.per_page_options[0] ?? null,
          currentPage: options.page
        },
        sort: {
          by: options.sortBy,
          desc: options.sortDesc,
          ...this.options.sortReset && { reset: this.options.sortReset }
        },
        filters: { ...activeFilters, ...this.additionalFilters },
        ...companyScopeId && { company_scope_id: companyScopeId }
      }
      try {
        this.fetchingData = true
        const oldActiveColumns = [...this.activeColumns]

        await this.fetch(params)

        if (oldActiveColumns.length) {
          this.oldActiveColumns = [...oldActiveColumns]
        }
        if (options.itemsPerPage === null) {
          this.itemsInitiallyLoad = true
        }
      }
      finally {
        this.fetchingData = false
      }
    },

    openMapOverviewDialog (event) {
      this.mapOverviewData = event.data
      this.mapOverviewDialogOpen = true
    },

    closeMapOverviewDialog () {
      this.mapOverviewData = {}
      this.mapOverviewDialogOpen = false
    },

    async openEditDialog (item) {
      const response = await api()[this.module].get((this.viewConfig.routeString || '') + '/' + item.id)

      this.formData = this.formDefaults

      this.$nextTick(() => {
        this.editItem = Object.assign({}, response.data) // In generic forms this should be _.cloneDeep
      })

      if (this.preventDefaultEdit) {
        this.$emit('edit-item', this.editItem)
        return
      }

      this.createOrUpdateDialog = true
    },

    openChangeModelCompanyDialog (item) {
      this.changeCompanyModel = item

      this.openChangeCompanyDialog = true
    },

    openResetPasswordDialog (item) {
      this.resetPasswordId = item.id
      this.isResetPasswordDialogOpen = true
    },

    openImportDialog () {
      this.importDialogOpen = true
    },

    showAppropriateImport (importConfig) {
      if (importConfig?.type === 'multi') {
        if (importConfig.input_type === 'select') {
          this.showImportSelect = true
        }
      }
      else {
        this.openImportDialog()
      }
    },

    selectImportOption (event) {
      this.importSelectedOptionFormatted = event.replace(/[-\s]+/g, '_').toLowerCase()
      this.importSelectedOption = event
      this.openImportDialog()
    },

    downloadImportTemplate () {
      const link = document.createElement('a')
      document.body.appendChild(link)
      const path = this.isMultiImport
        ? this.viewConfig.import.config_map.ecomobile.template_route
        : this.viewConfig.import.template_route
      link.href = `${window.location.origin}/api/${this.module}/${path}`
      link.download = 'true'
      link.click()
      document.body.removeChild(link)
    },

    async importConfirmed () {
      this.importOrExportLoading = true
      this.importValidationErrors = {}

      try {
        const formData = new FormData()
        formData.append('file', this.importFile)

        await api()[this.module].post((this.getImportRoute), formData)
          .then(() => {
            this.closeImportDialog()
            this.getItems(false, this.companyScopeId)
          })
      }
      catch (e) {
        this.importValidationErrors = e.getValidationErrors()
      }

      this.importOrExportLoading = false
    },

    handleExport () {
      this.importOrExportLoading = true
      const path = this.viewConfig.export.route
      const url = new URL(`${window.location.origin}/api/${this.module}/${path}`)
      this.activeColumns.forEach(column => url.searchParams.append('columns[]', column))
      url.searchParams.append('filters', JSON.stringify(this.activeFilters))
      const sort = this.sortOptions
      url.searchParams.append('sort', JSON.stringify(sort))
      let objectURL = null

      fetch(url.toString())
        .then(res => {
          if (!res.ok) {
            throw new Error(res.statusText)
          }
          return res.arrayBuffer()
        })
        .then(arrayBuffer => {
          // BE endpoint sends a readable stream of bytes
          const byteArray = new Uint8Array(arrayBuffer)
          const link = document.createElement('a')
          objectURL = URL.createObjectURL(
            new Blob(
              [byteArray],
              { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
            )
          )
          link.href = objectURL
          link.download = `${this.viewConfig.export.filename}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .finally(() => {
          this.importOrExportLoading = false
          if (objectURL) URL.revokeObjectURL(objectURL)
        })
    },

    handleCreateBtnClick () {
      if (this.preventDefaultCreate) {
        this.$emit('create-item')
        return
      }

      this.createOrUpdateDialog = true
    },

    closeEditDialog () {
      document.getElementsByClassName('v-card-text').scrollTop = 0
      this.editItem = null
      this.formData = Object.assign({ ...this.additionalFormData }, this.formDefaults)
      this.createDate = null
      this.groups = []
      this.createOrUpdateDialog = false
      this.validationErrors = {}
      this.$emit('close-edit')
      this.updateDigitalSensorsConfig(null)
      this.resetDynamicArrays()
    },

    async saveConfirmed () {
      if (this.saving) return

      this.saving = true

      this.validationErrors = {}

      const formData = {
        ...this.formData,
        ...this.additionalFormData,
        ...(this.companyScopeId && { company_scope_id: this.companyScopeId }),
        // TODO: Refactor this to be dynamic and to handle situations where multiple dates are present in create form
        ...(this.createDate && { [this.createDateKey]: this.createDate }),
        ...this.dynamicArrays
      }

      if (formData.options && typeof formData.options === 'object' && formData.options.id) {
        formData.options = formData.options.id
      }

      if ((!formData.active_from && !formData.active_to) || (formData.active_from !== formData.allowed_vehicle_use_from || formData.active_to !== formData.allowed_vehicle_use_to)) {
        if (formData.allowed_vehicle_use_from && formData.allowed_vehicle_use_to) {
          formData.active_from = formData.allowed_vehicle_use_from
          formData.active_to = formData.allowed_vehicle_use_to
          delete formData.allowed_vehicle_use_from
          delete formData.allowed_vehicle_use_to
        }
      }

      this.convertTextValueToDefaultColumns(formData)

      try {
        if (this.editItem === null) {
          await api()[this.module].post((this.viewConfig.routeString || ''), formData)
        }
        else {
          await api()[this.module].patch((this.viewConfig.routeString || '') + '/' + this.editItem.id, formData)
        }
        await this.getItems(false, this.companyScopeId)
        this.closeEditDialog()

        this.$emit('form-saved')
        this.selectedItems = []
      }
      catch (e) {
        this.validationErrors = e.getValidationErrors()
      }
      finally {
        this.saving = false
      }
    },

    openDeleteDialog (item) {
      this.deleteId = item.id
      this.deleteDialog = true
    },

    closeDeleteDialog () {
      this.deleteDialog = false
    },

    closeExceptionDialog () {
      this.exceptionDialog = false
    },

    closeImportDialog () {
      this.importFile = null
      this.importDialogOpen = false
      this.importValidationErrors = {}
      this.resetCustomImportValues()
    },

    resetCustomImportValues () {
      this.showImportSelect = false
      this.importSelectedOption = null
    },

    resetCustomImportValuesOnBlur () {
      if (!this.importSelectedOption) this.resetCustomImportValues()
    },

    async deleteConfirmed () {
      try {
        await api()[this.module].delete((this.viewConfig.routeString || '') + '/' + this.deleteId)
      }
      catch (e) {
        if (e.isForbiddenError) {
          const { title, message } = e.response.data
          this.exceptionTitle = title
          this.exceptionMessage = message
          this.deleteDialog = false
          this.exceptionDialog = true
        }
      }
      await this.getItems(false, this.companyScopeId)
      this.emitEventAndRemoveItemFromSelected(this.deleteId)
      this.closeDeleteDialog()
    },

    emitEventAndRemoveItemFromSelected (deletedItemId) {
      this.selectedItems = this.selectedItems.filter(item => item.id !== deletedItemId)
      this.$emit('item-deleted', this.selectedItems)
    },

    openChangeStatusDialog (item) {
      this.statusChangeLoading[item.id] = true
      this.changeStatusId = item.id
      this.selectedItemActive = !item.deactivated_at
      this.changeStatusDialog = true
    },

    closeChangeStatusDialog () {
      this.selectedItemActive = false
      this.changeStatusDialog = false
      this.statusChangeLoading = []
    },

    async changeStatusConfirmed () {
      await api()[this.module].patch((this.viewConfig.routeString || '') + '/' + this.changeStatusId + '/' + (this.selectedItemActive ? 'deactivate' : 'activate'))
      await this.getItems(false, this.companyScopeId)
      this.changeStatusDialog = false
      this.statusChangeLoading = []
    },

    async resetPasswordConfirmed () {
      await api()[this.module].post((this.viewConfig.routeString || '') + '/' + this.resetPasswordId + '/reset-password')
      this.isResetPasswordDialogOpen = false
      this.resetPasswordId = -1
    },

    filtersUpdated (payload) {
      this.$emit('set-show-filters', false)
      this.activeFilters = payload
      this.getItems(true, this.companyScopeId)
    },

    clearInputValidationErrors (key) {
      if (has(this.validationErrors, key)) {
        this.validationErrors[key] = []
      }

      if (this.validationErrors.ain_voltage_limit) {
        this.validationErrors.ain_voltage_limit = []
      }

      // This section handles the clearance of validation errors that persist when a user switches from one sensor type to another.
      // If validation errors from the previously selected sensor type persist, they will be removed to ensure accurate validation for the new selection.
      if (this.formData.options && typeof this.formData.options !== 'object' && (this.formData.options.includes('din') || this.formData.options.includes('din mode'))) {
        this.$delete(this.formData, 'ain_voltage_limit')
        this.$delete(this.formData, 'ain_notify')
      }
      else {
        if (this.formData.options && typeof this.formData.options !== 'object') {
          this.$delete(this.formData, 'din_notify_after')
        }
      }
    },

    canSeePermissions (relation) {
      return relation.allowed
    },

    formatMultiautocompleteResourceDisplayField (resource, selected, autocompleteOptions) {
      return resource.reduce((acc, field, currentIndex, array) => {
        const name = toLower(field.name.split('.')[0])
        const moduleName = autocompleteOptions.find(item => {
          return item.module === name
        })?.module_name

        const resourceDisplayField = field[selected.display_field]
        const resourceDisplayFieldFormatted = `${resourceDisplayField} (${moduleName})`
        return (acc += (currentIndex !== array.length - 1) ? `${resourceDisplayFieldFormatted}, <br>` : resourceDisplayFieldFormatted)
      }, '')
    },

    checkForSimInEditForm () {
      return this.viewConfig.edit_form?.form?.sim
    },

    checkForDigitalSensorInEditForm () {
      return this.viewConfig.edit_form?.form?.digital_sensor_status
    },

    checkForCanBusInEditForm () {
      return this.viewConfig.edit_form?.form?.can_bus_status
    },

    checkForFuelProbeInEditForm () {
      return this.viewConfig.edit_form?.form?.fuel_probe_1
    },

    showChangeDialogException (exceptionData) {
      this.openChangeCompanyDialog = false
      this.changeCompanyModel = {}
      this.exceptionDialog = true
      this.exceptionTitle = exceptionData.title
      this.exceptionMessage = exceptionData.message
    },

    closeChangeCompanyDialog () {
      this.openChangeCompanyDialog = false
      this.changeCompanyModel = {}
      this.getItems(false, this.companyScopeId)
    },

    closeResetPasswordDialog () {
      this.isResetPasswordDialogOpen = false
      this.resetPasswordId = -1
    },

    showResource (item) {
      if (this.viewConfig.show_single_resource) {
        this.$router.push({ name: this.showResourceRouteName, params: { id: item.id } })
      }
    },

    isEmpty (value) {
      return isEmpty(value)
    },

    parseDmyDate (date) {
      return parseDmyDate(date)
    },

    companyScopeColWidth (screenSize) {
      const companyScopeSizes = {
        xl: { create: 2, noCreate: 4 },
        lg: { create: 2, noCreate: 4 },
        md: { create: 2, noCreate: 3 },
        sm: { create: 4, noCreate: 5 }
      }

      const screenSizeObject = companyScopeSizes[screenSize]
      return this.noCreate ? screenSizeObject.noCreate : screenSizeObject.create
    },

    showAlarmEventLocation (item, column) {
      const columnConfig = this.viewConfig.columns[column]
      return columnConfig.data_type === 'map_location' &&
        columnConfig.valid_icon_modal_models.includes(item.eventable_type)
    },

    openAlarmEventLocation (item) {
      this.locationCoordinates = item.location
      this.vehicleId = item.vehicle_id
      this.openLocationMapModal = true
    }
  }
}
</script>

<style scoped lang="scss">
.v-card__title {
  padding: 10px 8px 8px 16px;
  font-size: 1rem;
}

.table-data-preview {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}
::v-deep .theme--light.v-tabs > .v-tabs-bar {
  width: 21%;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

::v-deep .form-fields-card {
  border: 2px solid var(--v-grey-lighten-1-base) !important;
  .tabs-wrapper {
    border-top: 2px solid var(--v-grey-lighten-1-base);

    .v-window-item {
      min-height: 65vh;

      .form-inputs-card {
        border-radius: 0 !important;
      }
    }

    .v-item-group {
      &:first-of-type {
        border-right: 2px solid var(--v-grey-lighten-1-base) !important;

        .v-slide-group__wrapper {
          margin-top: 0.5rem;
        }
      }
    }
  }
}

.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 36px;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: normal;
  text-transform: none;
}
.v-tab--active {
  background: #E2E2E2;
}
::v-deep .v-tabs-slider-wrapper {
  height: 36px;
  width: 6px !important;
  .v-tabs-slider {
    background-color: #6aac49;
  }
}

.header-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.toolbar-container {
  padding: 0 1rem;
}

.toolbar-title {
  color: #869DB6;
}

.map-location {
  cursor: pointer;
}

::v-deep {
  .row-pointer {
    div.v-data-table__wrapper {
      > table > tbody > tr :hover {
        cursor: pointer;
      }
    }
  }
}

</style>

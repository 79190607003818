var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"position":"relative"},attrs:{"fluid":""}},[_c('v-card',{attrs:{"disabled":_vm.fetchingData}},[_c('v-data-table',{directives:[{name:"stripped",rawName:"v-stripped"}],staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.list,"server-items-length":_vm.pagination.total,"items-per-page":_vm.pagination.perPage,"footer-props":{
      itemsPerPageOptions: [10, 50, 100]
    },"options":_vm.options,"loading":_vm.fetchingData,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"toolbar-container",attrs:{"flat":""}},[_c('div',{staticClass:"header-row"},[_c('v-toolbar-title',{staticClass:"toolbar-title",domProps:{"textContent":_vm._s(_vm.viewConfig.title)}}),_c('v-col',[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getItems}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.viewConfig.create_button),expression:"!!viewConfig.create_button"}],staticClass:"white--text px-4 mr-3 pr-5",attrs:{"disabled":!_vm.canCreate,"rounded":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleCreateBtnClick($event)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.viewConfig.create_button)+" ")],1)],1)]),_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showFiltersDialog = true}}},[_c('v-icon',{attrs:{"color":"#869DB6"}},[_vm._v(" mdi-filter-variant ")])],1)],1),_c('v-divider')]},proxy:true},{key:"item.only_patrols",fn:function(ref){
    var item = ref.item;
return [_c('div',[(item.only_patrols)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"23px","color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,true)},[_c('span',[_vm._v("Vidljivo samo na ophodarima")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"23px","color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_c('span',[_vm._v("Vidljivo na prijekopima i ophodarima")])])],1)]}},{key:"item.cityDistricts",fn:function(ref){
    var item = ref.item;
return [(item.cityDistricts && item.cityDistricts.length)?_c('div',_vm._l((item.cityDistricts),function(cityDistrict,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(cityDistrict.name)+" "),(index < item.cityDistricts.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])}),0):_c('div',[_c('span',[_vm._v("-")])])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","offset-overflow":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(_vm.canSeeActions(item))?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")]):_vm._e()]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},[(_vm.canBeEdited(item))?_c('v-list-item',{staticClass:"d-flex align-center list-item-min-height",attrs:{"link":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_c('v-list-item-icon',{staticClass:"align-self-center my-0 mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.viewConfig.actions.edit.icon || '')+" ")])],1),_c('v-list-item-title',{staticClass:"main-font-size"},[_vm._v(" "+_vm._s(_vm.viewConfig.actions.edit.text || '')+" ")])],1):_vm._e(),(_vm.canBeDeleted(item))?_c('v-list-item',{staticClass:"d-flex align-center list-item-min-height",attrs:{"link":""},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_c('v-list-item-icon',{staticClass:"align-self-center my-0 mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.viewConfig.actions.delete.icon || '')+" ")])],1),_c('v-list-item-title',{staticClass:"main-font-size"},[_vm._v(" "+_vm._s(_vm.viewConfig.actions.delete.text || '')+" ")])],1):_vm._e()],1)],1)]}}],null,true)})],1),_c('adjust-road-administration-dialog',{attrs:{"open-dialog":_vm.createOrUpdateDialog,"city-districts":_vm.cityDistricts,"dialog-title":_vm.dialogTitle,"edit-item":_vm.editItem},on:{"close-dialog":_vm.closeAdjustRoadAdministrationDialog,"update-table":_vm.getItems}}),(_vm.showFiltersDialog)?_c('table-filters-dialog',{attrs:{"show-dialog":_vm.showFiltersDialog,"dialog-title":_vm.filtersDialogConfig.title,"config":_vm.filtersDialogConfig.config,"filters":_vm.filtersDialogConfig.filters},on:{"close":_vm.closeFiltersDialog}}):_vm._e(),_c('delete-road-administration-dialog',{attrs:{"open-dialog":_vm.deleteDialog,"road-administration-for-delete":_vm.roadAdministrationForDelete},on:{"close-dialog":_vm.closeDeleteDialog,"update-table":_vm.getItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
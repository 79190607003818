<template>
  <map-base
    ref="mapBase"
    :style="mapStyle"
    class="map-excavation-work-orders-base"
    :map-options="mapOptions"
    :update-center="resetIcon"
    :map-options-picker-config="mapPickerConfig"
    @map-options-updated="mapOptionsUpdated"
    @marker-zoom-updated="zoomLevel => $emit('set-history-switch-visible', zoomLevel > 14)"
  >
    <template #mapContent>
      <create-work-order-modal
        :is-opened="isModalOpen"
        :edit-item-prop="editItem.data"
        :editing="editing"
        :map-class="'traffic-lights-edit-location-map-modal'"
        :can-edit="canEdit"
        @pull-data="emitPullData"
        @close-modal="closeModal"
      />
    </template>
  </map-base>
</template>

<script>
import L from 'leaflet'
import { createNamespacedHelpers } from 'vuex'
import CreateWorkOrderModal from './CreateWorkOrderModal.vue'
import MapBase from '@/global/components/map/MapBase.vue'
import { api } from '@/global/services/api'
import { isEmpty } from 'lodash'
import store from '@/global/store'
const { mapActions: mapActionsExcavationOrders, mapGetters: mapGettersExcavationOrders } = createNamespacedHelpers('road-maintenance/excavation-orders')

const DEFAULT_ICON_SIZE = [12, 12]
const SELECTED_ICON_SIZE = [32, 32]
export default {
  name: 'MapActiveWorkOrders',

  components: {
    CreateWorkOrderModal,
    MapBase
  },

  props: {
    showHistory: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({})
    },
    permissions: {
      type: Array,
      required: true,
      default: () => ([])
    },
    invokeGetIcons: {
      type: Boolean,
      default: false
    },
    cityDistricts: {
      type: Array,
      default: () => ([])
    },
    selectedIcon: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      markers: {},
      markersClusterGroup: {},
      historyMarkers: [],
      oldSelectedItem: null,
      isModalOpen: false,
      editItem: {},
      editing: true,
      mapStyle: {
        zIndex: 0,
        flex: 1
      },
      markerConfig: [],
      ready: false,
      mapOptions: {
        renderer: L.canvas()
      },
      mapPickerConfig: {
        content: [
          {
            title: 'Opcije',
            items: [
              {
                id: 'showCityDistricts',
                label: 'Prikaži gradske četvrti'
              }
            ]
          }
        ]
      },
      markerReset: false
    }
  },

  computed: {
    ...mapGettersExcavationOrders(['mapItems', 'icons', 'historyMapItems']),

    active () {
      return this.$route.name === 'ExcavationActiveWorkOrders'
    },

    canEdit () {
      const [permissions] = this.permissions
      return permissions?.canEdit === true
    }
  },

  watch: {
    async showHistory (state) {
      if (state) {
        this.$refs?.mapBase?.startMapLoader()
        await this.generateHistoryIcons()
        this.$refs?.mapBase?.lockMap()
        this.$refs?.mapBase?.stopMapLoader()
      }
      else {
        this.$refs?.mapBase?.startMapLoader()
        this.$refs?.mapBase?.unlockMap()
        this.$refs?.mapBase?.removeMarkers()
        await this.generateIcons(false)
        this.$refs?.mapBase?.stopMapLoader()
      }
    },

    async invokeGetIcons () {
      await this.generateIcons()
      this.$emit('reset-invoke-get-icons')
    },

    async options (newOptions) {
      if (newOptions) {
        this.$refs?.mapBase?.startMapLoader()
        await this.getIcons({
          active: this.active,
          statuses: newOptions.excavationOrderTypes,
          from: newOptions.from,
          to: newOptions.to,
          consentNumber: newOptions.consentNumber,
          address: newOptions.address,
          year: newOptions.year
        })

        this.mapItems.forEach(item => {
          item.click = () => {
            this.openEditDialog(item.id)
          }
        })
        const markersConfig = {
          markers: this.mapItems,
          icons: this.icons,
          markerClusterOptions: {
            maxClusterRadius: 40
          }
        }

        this.generateMarkers(markersConfig)
        this.$refs?.mapBase?.stopMapLoader()
      }
    },

    // Manage marker selection
    async selectedIcon (item) {
      try {
        if (item && item.id && item.latitude && item.longitude) {
          // Each marker on map has an id defined so that we can find them by it
          const newSelectedMarker = this.$refs.mapBase?.findMarkerById(item.id)

          // ************************* Old selected marker styling *************************

          // If there is already selected marker, reset its icon size to default [12, 12] and remove 'selected' class from it
          if (this.oldSelectedItem) {
            // Find old selected marker object
            const oldSelectedMarker = this.$refs.mapBase?.findMarkerById(this.oldSelectedItem.id)

            if (oldSelectedMarker) {
              // Get class from old selected marker so that we can remove previously added 'selected' class from it by applying new empty class ''
              const oldSelectedMarkerClass = oldSelectedMarker.getIcon()?.options?.className

              if (oldSelectedMarkerClass) {
                // Define new icon
                const newIcon = L.divIcon({
                  // Remove 'selected' class
                  className: oldSelectedMarkerClass.replace(' selected', ''),
                  // Define icon size
                  iconSize: DEFAULT_ICON_SIZE
                })
                // Apply new styled icon to old selected marker
                oldSelectedMarker.setIcon(newIcon)
              }
            }
          }

          // ************************* New selected marker styling *************************

          // Set map view to new selected marker coordinates
          this.$refs?.mapBase?.$refs?.map?.mapObject?.setView(L.latLng([item.latitude, item.longitude]), 16)

          if (newSelectedMarker) {
            // Set styling for new selected marker (css class and icon size)
            const newSelectedMarkerClass = newSelectedMarker.getIcon()?.options?.className

            if (newSelectedMarkerClass) {
              // Define new icon
              const newIcon = L.divIcon({
                // Add 'selected' class to icon of new selected marker
                className: newSelectedMarkerClass + ' selected',
                // Define icon size
                iconSize: SELECTED_ICON_SIZE
              })
              // Apply new icon to new selected marker
              newSelectedMarker.setIcon(newIcon)
            }

            this.markerReset = false
            // Remember new selected item as old so that we can find marker object later when new selected marker comes
            this.oldSelectedItem = item
          }
        }
        else if (!this.markerReset && !this.showHistory) {
          store.dispatch('base/notifications/push', 'Nije moguće prikazati marker zbog greške u konfiguraciji')
        }
      }
      catch (exception) {
        store.dispatch('base/notifications/push', 'Dogodila se pogreška prilikom prikaza markera')
        console.log(exception)
      }
    }
  },
  methods: {
    ...mapActionsExcavationOrders(['getIcons']),

    async generateIcons (fitMarkers = true) {
      await this.getIcons({
        active: this.active,
        statuses: this.options.excavationOrderTypes,
        from: this.options.from,
        to: this.options.to,
        consentNumber: this.options.consentNumber,
        address: this.options.address,
        year: this.options.year,
        cityDistricts: this.options.cityDistricts,
        dateKey: this.options.dateKey
      })
      if (this.mapItems.length) {
        this.mapItems.forEach(item => {
          item.click = () => {
            this.openEditDialog(item.id)
          }
        })

        const markersConfig = {
          markers: this.mapItems,
          icons: this.icons,
          markerClusterOptions: {
            maxClusterRadius: 40
          },
          fitMarkers: fitMarkers
        }
        this.generateMarkers(markersConfig)
      }
      else {
        this.$refs.mapBase?.removeMarkers()
      }
    },

    // This method will occur when user starts to move map (map center is updated)
    // Deselect current selected marker by applying new icon style
    resetIcon (value) {
      if (this.selectedIcon && this.selectedIcon.latitude && this.selectedIcon.longitude && value.lat && value.lng) {
        // Get current selected marker
        const currentSelectedMarker = this.$refs.mapBase?.findMarkerById(this.selectedIcon.id)

        if ((value.lat.toFixed(4) !== this.selectedIcon.latitude.toFixed(4) || value.lng.toFixed(4) !== this.selectedIcon.longitude.toFixed(4)) && currentSelectedMarker) {
          const currentSelectedMarkerClass = currentSelectedMarker.getIcon()?.options?.className

          if (currentSelectedMarkerClass) {
            // Define new icon
            const oldIcon = L.divIcon({
              // Remove 'selected' class
              className: currentSelectedMarkerClass.replace(' selected', ''),
              // Reset icon size
              iconSize: [12, 12]
            })

            // Apply new icon to current selected marker
            currentSelectedMarker.setIcon(oldIcon)
          }

          this.markerReset = true
          // Tell parent to remove remembered selected icon
          this.$emit('reset-zoom')
        }
      }
    },

    closeModal () {
      this.isModalOpen = false
      this.$emit('close-edit-dialog')
    },

    async openEditDialog (item) {
      this.editItem = await api('road-maintenance').get('/excavation-orders/' + item)
      this.$emit('open-edit-dialog-map')
      this.isModalOpen = true
    },

    mapOptionsUpdated (updatedOptions) {
      if (updatedOptions.showCityDistricts) {
        if (this.cityDistricts && this.cityDistricts.length) {
          const polygonConfig = {
            polygons: []
          }
          this.cityDistricts.forEach(cityDistrict => {
            if (cityDistrict && !isEmpty(cityDistrict) && cityDistrict.data) {
              const coordinatesString = cityDistrict.data.match(/\(\(([^)]+)\)\)/)[1]

              const coordinatesPairs = coordinatesString.split(', ')

              const coordinatesArray = coordinatesPairs.map(pair => {
                const [latitude, longitude] = pair.split(' ').map(parseFloat)
                return [latitude, longitude]
              })
              polygonConfig.polygons.push({
                coordinates: coordinatesArray,
                label: cityDistrict.name,
                options: {
                  color: cityDistrict.color
                }
              })
              this.$refs?.mapBase?.generatePolygons(polygonConfig)
            }
          })
        }
      }
      else {
        this.$refs?.mapBase?.removeAllPolygons()
      }
    },

    async generateHistoryIcons () {
      const bounds = this.$refs?.mapBase?.getCurrentMapBounds()
      const minLat = bounds._southWest.lat
      const minLon = bounds._southWest.lng
      const maxLat = bounds._northEast.lat
      const maxLon = bounds._northEast.lng
      await this.getIcons({
        active: false,
        min_lat: minLat,
        min_lon: minLon,
        max_lat: maxLat,
        max_lon: maxLon,
        from: this.options.from,
        statuses: this.options.excavationOrderTypes
      })
      this.historyMapItems.forEach(item => {
        item.click = () => {
          this.openEditDialog(item.id)
        }
      })
      const markersConfig = {
        markers: this.historyMapItems,
        icons: this.icons,
        keepExisting: true,
        markerClusterOptions: {
          maxClusterRadius: 40
        },
        fitMarkers: false
      }
      this.generateMarkers(markersConfig)
    },

    generateMarkers (markersConfig) {
      this.$nextTick(() => {
        this.$refs?.mapBase?.onMapReady(function (map) {
          map.generateMarkers(markersConfig)
        })
      })
    },

    emitPullData () {
      this.$emit('pull-data')
    },

    // Invalidate map size function to be called from parent component so the missing tiles are fetched
    invalidateMapSize () {
      this.$refs?.mapBase?.getMapObject()?.invalidateSize()
    }
  }
}
</script>

<style scoped>
/*
  If multiple controls are added into the controls slot, they should be separated with a v-divider.
  This styles the v-divider to maintain consistent spacing.
*/
.map-excavation-work-orders-base {
  .leaflet-control .v-divider{
    margin: 8px 0;
  }
  .v-icon {
    font-size: 1.5rem;
  }
  .v-label {
    font-size: 0.82rem;
  }

  .red_icon {
    background: #ca2a19;
  }

  .yellow_icon {
    background: #ffe545;
  }

  .green_icon {
    background: #519548;
  }

  .orange_icon {
    background: #ff8f40;
  }

  .gray_icon {
    background: #4f4f4f;
  }

  .white_icon {
    background: #ffffff;
  }

  /* Default style for all icons */
  .red_icon,
  .yellow_icon,
  .green_icon,
  .white_icon,
  .gray_icon,
  .orange_icon{
    z-index:1000;
    border-radius: 50%;
    box-shadow: 0 0 10px 2px;
  }

  /* Change shadow for all icons on hover */
  .red_icon:hover,
  .yellow_icon:hover,
  .green_icon:hover,
  .white_icon:hover,
  .gray_icon:hover,
  .orange_icon:hover
  {
    box-shadow: 0 0 10px 2px orange;
  }

  /* Selected state for all icons */
  .red_icon.selected,
  .yellow_icon.selected,
  .green_icon.selected,
  .white_icon.selected,
  .gray_icon.selected,
  .orange_icon.selected {
    border-radius: 50%;
    box-shadow: 0 0 10px 2px orange;
  }
}
</style>

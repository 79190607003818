<template>
  <div v-if="this.$route.meta.name === 'ROAD_ADMINISTRATION'">
    <road-administration
      view-name="road-administrations"
      :module="module"
      :model="model"
      :list="list"
      :view-config="viewConfig"
      :pagination="pagination"
      :show-loader="loader"
      :noActions="!canUpdate && !canDelete"
      :city-districts="cityDistricts"
      :fetch="fetch"
      :filters-dialog-config="filtersDialogConfig"
    />
  </div>
  <div v-else>
    <index-view
      view-name="excavation-settings"
      :module="module"
      :model="model"
      :list="list"
      :view-config="viewConfig"
      :pagination="pagination"
      :show-loader="loader"
      :noActions="!canUpdate && !canDelete"
      :fetch="fetch"
      :filters-dialog-config="filtersDialogConfig"
    />
  </div>
</template>

<script>
import indexView from '@/modules/road-maintenance-module/excavation-settings/components/Index.vue'
import { createNamespacedHelpers } from 'vuex'
import { forEach } from 'lodash'
import RoadAdministration from '../components/RoadAdministration.vue'
const { mapGetters, mapActions } = createNamespacedHelpers('road-maintenance/excavation-settings')

export default {
  name: 'ExcavationSettingsIndex',

  components: {
    RoadAdministration,
    'index-view': indexView
  },

  props: {
    module: {
      type: String,
      default: 'road-maintenance'
    },
    model: {
      type: String,
      default: 'excavation-settings'
    }
  },

  data () {
    return {
      options: {
        page: 1,
        itemsPerPage: 5
      },
      loading: false,
      dropdownType: false,
      createTitle: '',
      updateTitle: '',
      deleteTitle: '',
      confirmDelete: '',
      title: '',
      importTitle: '',
      filtersDialogConfig: {
        show: false,
        title: this.$t('base.filters'),
        config: {
          fields: []
        },
        filters: {}
      },
      commonFilters: [
        {
          key: 'name',
          label: 'Naziv'
        },
        {
          key: 'contact_person',
          label: 'Kontakt osoba'
        },
        {
          key: 'email',
          label: 'E-mail'
        },
        {
          key: 'phone',
          label: 'Telefon'
        },
        {
          key: 'road_administration_name',
          label: 'Nadcestarija'
        },
        {
          key: 'city_district_name',
          label: 'Gradska četvrt'
        },
        {
          key: 'only_patrols',
          label: 'Samo ophodari',
          type: 'checkbox'
        },
        {
          key: 'note',
          label: 'Komentar'
        }
      ]
    }
  },
  async created () {
    this.fetchPermissions()
    this.dropdownType = this.$route.meta.name
    this.createTitle = this.$route.meta.create_title
    this.updateTitle = this.$route.meta.edit_title
    this.deleteTitle = this.$route.meta.delete_title
    this.confirmDelete = this.$route.meta.confirm_delete
    this.title = this.$route.meta.title
    this.importTitle = this.$route.meta.import_title
    this.setDropdownType(this.dropdownType)
    if (!this.canUpdate && !this.canDelete) {
      delete this.viewConfig.actions
    }
    this.setViewConfig(this.viewConfig)
    await this.fetchCityDistricts()
    this.mapFilters()
  },
  computed: {
    ...mapGetters(['list', 'pagination', 'viewConfig', 'permissions', 'cityDistricts']),

    canCreate () {
      const [permissions] = this.permissions
      return permissions?.createSettings === true
    },

    canUpdate () {
      const [permissions] = this.permissions
      return permissions?.updateSettings === true
    },

    canDelete () {
      const [permissions] = this.permissions
      return permissions?.deleteSettings === true
    },

    canImport () {
      const [permissions] = this.permissions
      return permissions?.importSettings === true
    },

    canExport () {
      const [permissions] = this.permissions
      return permissions?.exportSettings === true
    },

    viewConfig () {
      const columns = {}
      var personArray = [
        'ExcavationSettingsQuarters',
        'ExcavationSettingsDistrictOffices',
        'ExcavationSettingsRoadCategories',
        'ExcavationSettingsPurposesOfExcavation'
      ]

      const columnNames = ['name', 'contact_person', 'email', 'phone', 'note']
      const labels = [
        this.$t(this.$route.meta.name_label),
        this.$t('road-maintenance/excavation-dropdown-type.contact_person'),
        this.$t('road-maintenance/excavation-dropdown-type.email'),
        this.$t('road-maintenance/excavation-dropdown-type.phone'),
        this.$t('road-maintenance/excavation-dropdown-type.note')
      ]
      if (personArray.includes(this.$route.name)) {
        columnNames.splice(1, 3)
        labels.splice(1, 3)
      }
      forEach(columnNames, function (column, key) {
        columns[column] = {
          type: 'free',
          label: labels[key],
          data_type: 'text',
          field: column,
          sortable: true,
          always_visible: true,
          creatable: true,
          editable: true,
          filterable: true,
          dataTable: true,
          initially_visible: true
        }
      })
      return {
        ui_view_name: 'road-maintenance',
        routeString: 'excavation-settings/' + this.dropdownType,
        title: this.$t('road-maintenance/excavation-service.overview'),
        create_button: this.canCreate ? this.$t(this.createTitle) : false,
        show_single_resource: false,
        active_filters: 0,
        per_page_options: [20, 50, 100],
        columns: columns,
        create_form: {
          title: this.$t(this.createTitle),
          button_save: this.$t('base.save'),
          button_cancel: this.$t('base.cancel'),
          form: columns
        },
        edit_form: {
          title: this.$t(this.updateTitle),
          button_save: this.$t('base.edit'),
          button_cancel: this.$t('base.cancel'),
          form: columns
        },
        actions: {
          create: this.canCreate,
          edit: this.canUpdate ? {
            icon: 'mdi-pencil',
            text: this.$t('base.edit')
          } : false,
          delete: this.canDelete ? {
            icon: 'mdi-delete',
            text: this.$t('base.delete'),
            confirmation_title: this.$t(this.deleteTitle),
            confirmation_message: this.$t(this.confirmDelete),
            confirmation_confirm: this.$t('base.delete'),
            confirmation_cancel: this.$t('base.cancel')
          } : false
        },
        sync: [],
        import: this.canImport ? {
          template_route: 'excavation-dropdown-value/import-template',
          route: 'excavation-dropdown-value/import',
          button_text: this.$t('road-maintenance/excavation-service.import'),
          title: this.$t(this.importTitle),
          template_button: this.$t('road-maintenance/excavation-service.download_template'),
          button_cancel: this.$t('base.cancel'),
          button_import: this.$t('road-maintenance/excavation-service.import_button')
        } : false,
        export: this.canExport ? {
          route: 'excavation-dropdown-value/export',
          button_text: this.$t('road-maintenance/excavation-service.export'),
          filename: this.$t(this.title)
        } : false
      }
    },
    loader: function () {
      return this.loading
    }
  },
  methods: {
    ...mapActions(['fetch', 'setDropdownType', 'setViewConfig', 'fetchPermissions', 'fetchCityDistricts']),

    mapFilters () {
      if (this.$route.meta.name) {
        switch (this.$route.meta.name) {
          case 'STAKEHOLDER':
            this.$set(this.filtersDialogConfig.config, 'fields', this.commonFilters.filter(filter => filter.key === 'name' || filter.key === 'contact_person' || filter.key === 'email' || filter.key === 'phone' || filter.key === 'note'))
            break
          case 'INVESTOR_RESPONSIBLE_PERSON':
            this.$set(this.filtersDialogConfig.config, 'fields', this.commonFilters.filter(filter => filter.key === 'name' || filter.key === 'contact_person' || filter.key === 'email' || filter.key === 'phone' || filter.key === 'note'))
            break
          case 'ZC_RESPONSIBLE_PERSON':
            this.$set(this.filtersDialogConfig.config, 'fields', this.commonFilters.filter(filter => filter.key === 'name' || filter.key === 'contact_person' || filter.key === 'email' || filter.key === 'phone' || filter.key === 'note'))
            break
          case 'ROAD_ADMINISTRATION':
            this.$set(this.filtersDialogConfig.config, 'fields', this.commonFilters.filter(filter => filter.key === 'road_administration_name' || filter.key === 'city_district_name' || filter.key === 'only_patrols'))
            break
          case 'ROAD_CATEGORY':
            this.$set(this.filtersDialogConfig.config, 'fields', this.commonFilters.filter(filter => filter.key === 'name' || filter.key === 'note'))
            break
          case 'EXCAVATION_REASON':
            this.$set(this.filtersDialogConfig.config, 'fields', this.commonFilters.filter(filter => filter.key === 'name' || filter.key === 'note'))
            break
        }
      }
    }
  }
}

</script>

<template>
  <div>
    <v-row>
<!--      Filter button-->
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
        class="mt-0"
      >
        <v-btn
          class="mt-4"
          plain
          @click="openFilterDialog"
        >
          <v-icon
            large
          >
            mdi-filter-variant
          </v-icon>
          <span class="ml-5">Filteri</span>
        </v-btn>
      </v-col>

<!--      From date datepicker-->
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-menu
          v-model="fromMenu"
          transition="slide-y-transition"
          nudge-top="450"
          offset-y
          min-width="auto"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromFormatted"
              :label="$t('base.from')"
              prepend-inner-icon="mdi-calendar-start"
              readonly
              v-bind="attrs"
              v-on="on"
              @blur="parseDmyDate(fromFormatted)"
            />
          </template>
          <datetime-picker
            v-model="from"
            :emit-default-time="true"
            :default-time="'00:00:00'"
            :max-date="maxDate"
            :reset-tabs="resetFromTabs"
            @close-picker="fromMenu = false"
            :on-double-click="closeDatetimePicker"
          >
            <v-col class="white text-right pb-2 px-2">
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="closeFromMenu"
              >
                {{ $t('base.ok') }}
              </v-btn>
            </v-col>
          </datetime-picker>
        </v-menu>
      </v-col>

<!--      To date datepicker-->
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-menu
          v-model="toMenu"
          transition="slide-y-transition"
          nudge-top="450"
          offset-y
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="toFormatted"
              :label="$t('base.to')"
              prepend-inner-icon="mdi-calendar-end"
              readonly
              v-bind="attrs"
              v-on="on"
              @blur="parseDmyDate(toFormatted)"
            />
          </template>
          <datetime-picker
            v-model="to"
            :emit-default-time="true"
            :default-time="defaultToTime"
            :min-date="minDate"
            :reset-tabs="resetToTabs"
            @close-picker="toMenu = false"
            :on-double-click="closeDatetimePicker"
          >
            <v-col class="white text-right pb-2 px-2">
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="closeToMenu"
              >
                {{ $t('base.ok') }}
              </v-btn>
            </v-col>
          </datetime-picker>
        </v-menu>
      </v-col>

<!--      Show table button-->
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-btn
          class="mt-4"
          plain
          @click="getFiltered"
        >
          <v-icon
            large
          >
            mdi-file-table
          </v-icon>
          <span class="ml-5">{{ $t('road-maintenance/patrol-service.show_order_list') }}</span>
        </v-btn>
      </v-col>

<!--      Open create popup button-->
      <v-col
        v-if="permissions && permissions[0] && permissions[0].canCreate !== undefined ? permissions[0].canCreate  : false"
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-btn
          class="mt-4"
          plain
          @click="showManagePrpModal = true"
        >
          <v-icon
            large
          >
            mdi-plus
          </v-icon>
          <span class="ml-5">{{ $t('road-maintenance/excavation-service.add-work-order') }}</span>
        </v-btn>
      </v-col>

<!--      Export button-->
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-btn
          class="mt-4"
          plain
          @click="exportData"
        >
          <v-icon
            large
          >
            mdi-application-export
          </v-icon>
          <span class="ml-5">{{ $t('road-maintenance/excavation-service.export') }}</span>
        </v-btn>
      </v-col>
    </v-row>

<!--    Filter dialog-->
    <v-dialog
      v-model="showFilterDialog"
      max-width="700px"
      persistent
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="consentNumber"
                  autofocus
                  :label="$t('road-maintenance/prp.consent_number')"
                  hide-details
                  class="mb-4"
                  clearable
                />
                <v-text-field
                  v-model="address"
                  label="Adresa"
                  hide-details
                  class="mb-4"
                  clearable
                />
                <v-text-field
                  v-model="year"
                  label="Godina"
                  :error-messages="getYearValidationMessage()"
                  class="mb-4"
                  hide-details="auto"
                  clearable
                />
<!--                Active switch filter-->
                <v-switch
                  v-model="isActiveStatus"
                  color="primary"
                  hide-details
                >
                  <template v-slot:label>
                    <div
                      class="green_icon circle"
                    />
                    <div
                      style="margin-left: 10px;"
                    >
                      Aktivni
                    </div>
                  </template>
                </v-switch>

<!--                Work finished at switch filter-->
                <v-switch
                  v-model="isWorkFinishedAtStatus"
                  color="primary"
                  hide-details
                >
                  <template v-slot:label>
                    <div
                      class="yellow_icon circle"
                    />
                    <div
                      style="margin-left: 10px;"
                    >
                      Odjavljeni
                    </div>
                  </template>
                </v-switch>

<!--                Inactive switch filter-->
                <v-switch
                  v-model="isInactiveStatus"
                  color="primary"
                  hide-details
                >
                  <template v-slot:label>
                    <div
                      class="white_icon circle"
                    />
                    <div
                      style="margin-left: 10px;"
                    >
                      Završeni
                    </div>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
            <v-row class="ma-0 flex flex-row-reverse mt-4">
              <v-btn
                color="primary"
                primary
                class="px-7 ml-5 mt-6"
                @click="filterStatus"
              >
                {{ $t('base.ok') }}
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <manage-prp-modal
      :is-opened="showManagePrpModal"
      :edit-item-prop="editItem"
      :editing="editing"
      :map-class="'prp-controls-modal'"
      :permissions="permissions"
      @pull-data="emitPullData"
      @close-modal="closeModal"
      @pull-fresh-edit-item="val => $emit('pull-fresh-edit-item', val)"
    />
  </div>
</template>

<script>
import DatetimePicker from '@/global/components/DatetimePicker.vue'
import ManagePrpModal from './ManagePrpModal.vue'
import { formatSqlDateTime, parseDmyDate } from '@/global/services/helpers/dates'
import dayjs from 'dayjs'
import KeyBinder from '@/global/services/helpers/KeyBinder'

const DATE_PICKER_FORMAT = 'YYYY-MM-DD'
const MIN_YEAR_IN_FILTER = 2000
export default {
  name: 'PrpControls',

  components: {
    DatetimePicker,
    ManagePrpModal
  },

  props: {
    openEditDialog: {
      type: Boolean,
      default: false
    },
    mapMarkerClicked: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({})
    },
    editItemProp: {
      type: Object,
      default: () => ({})
    },
    permissions: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      from: null,
      fromFormatted: null,
      to: null,
      toFormatted: null,
      companyScopeId: null,
      fromMenu: false,
      toMenu: false,
      resetFromTabs: false,
      resetToTabs: false,
      showFilterDialog: false,
      showManagePrpModal: false,
      editItem: {},
      editing: false,
      address: null,
      consentNumber: null,
      year: '',
      isActiveStatus: true,
      isInactiveStatus: false,
      isWorkFinishedAtStatus: true,
      validationErrors: {}
    }
  },

  computed: {
    defaultToTime () {
      return '23:59:59'
    },

    minDate () {
      return dayjs(this.from).format(DATE_PICKER_FORMAT)
    },

    maxDate () {
      return dayjs(this.to).format(DATE_PICKER_FORMAT)
    }
  },

  watch: {
    from (from) {
      this.fromFormatted = formatSqlDateTime(from, 'DD.MM.YYYY HH:mm')
    },

    to (to) {
      this.toFormatted = formatSqlDateTime(to, 'DD.MM.YYYY HH:mm')
    },

    openEditDialog (open) {
      this.showManagePrpModal = open
      this.editing = open
    },

    editItemProp (item) {
      this.editItem = item
    },

    fromMenu (value) {
      this.$emit('dialog-open-status', value)
      this.resetFromTabs = !this.resetFromTabs
    },

    toMenu (value) {
      this.$emit('dialog-open-status', value)
      this.resetToTabs = !this.resetToTabs
    }
  },

  created () {
    this.from = dayjs().subtract(1, 'year').toISOString()
    this.to = dayjs().toISOString()
    this.emitGetOptions()

    this.keyCallbacks = {
      f: this.handleKeyDownF,
      F: this.handleKeyDownF,
      Enter: this.handleKeyDownEnter,
      Escape: this.handleKeyDownEscape
    }

    this.keyBinder = new KeyBinder(this.keyCallbacks)
    this.keyBinder.bind()
  },

  destroyed () {
    if (this.keyBinder) {
      this.keyBinder.unbind()
    }
  },

  methods: {
    handleKeyDownF () {
      if (!this.showManagePrpModal && !this.showFilterDialog && !this.mapMarkerClicked) {
        this.showFilterDialog = true
      }
    },

    handleKeyDownEscape () {
      this.showFilterDialog = false
    },

    handleKeyDownEnter () {
      if (!this.showManagePrpModal && this.showFilterDialog && !this.validationErrors.year) {
        this.filterStatus()
        this.emitGetOptions()
        this.$emit('show-table')
      }
    },

    getYearValidationMessage () {
      if (!this.year) {
        this.$delete(this.validationErrors, 'year')
        return []
      }

      const isValidYearFormat = /^(?!0000)\d{4}$/.test(this.year) // Excludes '0000'

      // Check if the year is a 4-digit number and within the realistic range
      if (!isValidYearFormat || this.year < MIN_YEAR_IN_FILTER) {
        this.$set(this.validationErrors, 'year', this.$t('road-maintenance/prp.year_filter_validation_message'))
        return this.validationErrors.year
      }

      // Clear the error if the year is valid
      this.$delete(this.validationErrors, 'year')
      return []
    },

    closeDatetimePicker () {
      if (this.fromMenu) {
        this.fromMenu = false
      }
      if (this.toMenu) {
        this.toMenu = false
      }
      this.emitGetOptions()
      this.$emit('pull-data')
    },

    emitPullData () {
      this.$emit('pull-data')
    },

    closeFromMenu () {
      this.emitGetOptions()
      this.$emit('pull-data')
      this.fromMenu = false
    },

    closeToMenu () {
      this.emitGetOptions()
      this.$emit('pull-data')
      this.toMenu = false
    },

    closeModal () {
      this.showManagePrpModal = false
      this.$emit('close-edit-dialog')
    },

    parseDmyDate (date) {
      return parseDmyDate(date)
    },

    getFiltered () {
      this.emitGetOptions()
      this.$emit('show-table')
    },

    filterStatus () {
      if (!this.validationErrors.year) {
        this.emitGetOptions()
        this.$emit('pull-data')
        this.showFilterDialog = false
      }
    },

    openFilterDialog () {
      this.showFilterDialog = true
    },

    emitGetOptions () {
      this.$emit('get-options', {
        status: this.getSelectedStatuses(),
        from: formatSqlDateTime(this.from, DATE_PICKER_FORMAT),
        to: formatSqlDateTime(this.to, DATE_PICKER_FORMAT),
        consentNumber: this.consentNumber,
        address: this.address,
        year: this.year
      })
    },

    getSelectedStatuses () {
      const selectedStatuses = []

      if (this.isActiveStatus) {
        selectedStatuses.push('ACTIVE')
      }
      if (this.isInactiveStatus) {
        selectedStatuses.push('INACTIVE')
      }
      if (this.isWorkFinishedAtStatus) {
        selectedStatuses.push('WORK_FINISHED_AT')
      }

      return selectedStatuses
    },

    async exportData () {
      const params = {
        columns: [
          'applicant',
          'investor',
          'address',
          'consent_number',
          'start_date',
          'end_date',
          'zc_responsible_person',
          'status',
          'extensions',
          'work_finished_at'
        ],
        filters: {
          from: formatSqlDateTime(this.from, DATE_PICKER_FORMAT),
          to: formatSqlDateTime(this.to, DATE_PICKER_FORMAT),
          status: this.getSelectedStatuses(),
          address: this.address,
          consent_number: this.consentNumber,
          year: this.year
        },
        sort: {
          by: this.options.sortBy,
          desc: this.options.sortDesc
        }
      }
      this.importOrExportLoading = true
      const url = new URL(`${window.location.origin}/api/road-maintenance/temporary-traffic-regulation/export`)
      params.columns.forEach(column => url.searchParams.append('columns[]', column))
      url.searchParams.append('filters', JSON.stringify(params.filters))
      url.searchParams.append('sort', JSON.stringify(params.sort))
      let objectURL = null
      fetch(url.toString())
        .then(res => {
          if (!res.ok) {
            throw new Error(res.statusText)
          }
          return res.arrayBuffer()
        })
        .then(arrayBuffer => {
          // BE endpoint sends a readable stream of bytes
          const byteArray = new Uint8Array(arrayBuffer)
          const link = document.createElement('a')
          objectURL = URL.createObjectURL(
            new Blob(
              [byteArray],
              { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
            )
          )
          link.href = objectURL
          const filename = this.$t('road-maintenance.prp')
          link.download = `${filename}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .finally(() => {
          this.importOrExportLoading = false
          if (objectURL) URL.revokeObjectURL(objectURL)
        })
    }
  }
}
</script>
<style scoped lang="scss">
  h2 {
    font-size: 14px;
    font-weight: normal;
    padding-bottom: 0;
  }

  .circle{
    box-shadow: 0 0 5px 2px;
    border-radius: 50%;
    height:12px;
    width: 12px;
    margin-left: 10px;
  }
  .red_icon {
    background: #ca2a19;
  }

  .yellow_icon {
    background: #ffe545;
  }

  .green_icon {
    background: #519548;
  }

  .orange_icon {
    background: #ff8f40;
  }
</style>

<template>
  <div
    class="overflow-y-hidden"
    :style="wrapperStyles"
  >
    <!-- Desktop view -->
    <splitpanes
      horizontal
      :push-other-panes="false"
      class="default-theme h-full"
      @resize="resizeMap = !resizeMap"
    >
      <pane
        :size="firstPaneSize"
        max-size="88"
        min-size="12"
      >
        <!-- lazy because of problems with vuetify and vue-leaflet https://github.com/vue-leaflet/Vue2Leaflet/issues/96 -->
        <v-lazy class="h-full">
          <!-- Important to have wrapping div when using `v-lazy`, otherwise map layout will be broken -->
          <div class="h-full">
            <map-work-orders
              ref="mapWorkOrders"
              class="h-full"
              :options="options"
              :selected-icon="iconItem"
              :show-history="showHistory"
              :invoke-get-icons="invokeGetIcons"
              :key-binder-class="keyBinder"
              :city-districts="cityDistricts"
              :permissions="permissions"
              @get-data="getItems"
              @reset-invoke-get-icons="value => invokeGetIcons = false"
              @set-history-switch-visible="visible => historySwitchVisible = visible"
              @reset-zoom="value => this.iconItem = {}"
              @bind-keyBinder-class="keyBinder.bind()"
              @update:options="getItems"
            />
          </div>
        </v-lazy>
      </pane>
      <pane
        :size="secondPaneSize"
        min-size="12"
        max-size="88"
        class="overflow-y-auto"
      >
        <v-container fluid>
          <v-card>
            <v-card-text>
              <work-order-controls
                :history-switch-visible="historySwitchVisible"
                :reset-history="resetHistory"
                @show-table="showTable"
                @get-options="getOptions"
                @reset-history-trigger="value => resetHistory = false"
                @show-history="value => showHistory = value === 'true'"
                @save-bind-class="bindClass => keyBinder = bindClass"
              />
              <v-data-table
                v-show="tableVisible"
                v-stripped
                class="fill-height overflow-y-auto"
                :headers="active ? active_header : header"
                item-key="id"
                :items="list"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.perPage"
                :sort-by.sync="options.sortBy"
                :sort-desc.sync="options.sortDesc"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15]
                }"
                @update:options="getItems"
              >
                <template #[`item.created_at`]="{ item }">
                  {{ formatSqlDateTime(item.created_at) }}
                </template>
                <template #[`item.completed_at`]="{ item }">
                  {{ formatSqlDateTime(item.completed_at) }}
                </template>
                <template #[`item.subject_report`]="{ item }">
                  {{ item && item.subject_report && item.subject_report.road_administration_name ? item.subject_report.road_administration_name : ''}}
                </template>
                <template #[`item.category`]="{ item }">
                  {{ item && item.category && item.category.description ? $t(item.category.description) : '' }}
                </template>
                <template #[`item.hazard_level`]="{ item }">
                  {{ $t(item.hazard_level.description) }}
                </template>
                <template #[`item.showDetail`]="{ item }">
                  <v-btn
                    icon
                    @click="showWorkOrderDialog(item)"
                  >
                    <v-icon>mdi-file-table</v-icon>
                  </v-btn>
                </template>
                <template #[`item.showMap`]="{ item }">
                  <v-btn
                    icon
                    @click="selectIcon(item)"
                  >
                    <v-icon>mdi-map-marker-radius</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-container>
      </pane>
    </splitpanes>
    <v-dialog
      v-model="WorkOrderDialog"
      max-width="800px"
      :transition="false"
    >
      <show-work-order
        v-if="WorkOrderDialog"
        :item="workOrder"
        :permissions="permissions"
        @close="closeWorkOrderModal"
      />
    </v-dialog>
  </div>
</template>

<script>
import { debounce, forEach } from 'lodash'
import { createNamespacedHelpers } from 'vuex'
import MapWorkOrders from '@/modules/road-maintenance-module/patrol-service/components/MapWorkOrders.vue'
import WorkOrderControls from '@/modules/road-maintenance-module/patrol-service/components/WorkOrderControls.vue'
import { formatSqlDateTime } from '@/global/services/helpers/dates'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import ShowWorkOrder from '@/modules/road-maintenance-module/patrol-service/components/ShowWorkOrder.vue'
import { api } from '@/global/services/api'
const { mapGetters, mapActions } = createNamespacedHelpers('road-maintenance/work-orders')
export default {
  name: 'WorkOrdersIndex',
  components: {
    MapWorkOrders,
    WorkOrderControls,
    Splitpanes,
    Pane,
    ShowWorkOrder
  },

  data () {
    return {
      resizeMap: false,
      workOrder: null,
      WorkOrderDialog: false,
      tableVisible: false,
      firstPaneSize: 85,
      secondPaneSize: 15,
      historySwitchVisible: false,
      showHistory: false,
      resetHistory: false,
      invokeGetIcons: false,
      iconItem: {},
      filters: {},
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: ['id'],
        sortDesc: [true],
        active: false,
        hazard_level: false,
        subject_report: false,
        city_districts: false,
        from: false,
        to: false,
        address: '',
        tipNumber: ''
      },
      keyBinder: null
    }
  },
  computed: {
    header () {
      const data = []
      const columns = ['id', 'created_at', 'created_by', 'completed_at', 'completed_by', 'subject_report', 'category', 'hazard_level', 'address', 'description']
      const translatedColumns = [
        this.$t('road-maintenance/patrol-service.notification_number'),
        this.$t('road-maintenance/patrol-service.created_at'),
        this.$t('road-maintenance/patrol-service.created_by'),
        this.$t('road-maintenance/patrol-service.completed_at'),
        this.$t('road-maintenance/patrol-service.completed_by'),
        this.$t('road-maintenance/dropdown-types.subject_report'),
        this.$t('road-maintenance/dropdown-types.category'),
        this.$t('road-maintenance/dropdown-types.hazard_level'),
        this.$t('road-maintenance/patrol-service.address'),
        this.$t('road-maintenance/patrol-service.description')

      ]
      forEach(columns, function (column, key) {
        data.push({
          text: translatedColumns[key],
          value: column,
          align: 'center',
          class: 'data-table-header'
        })
      })
      data.push({
        text: this.$t('road-maintenance/patrol-service.show_details'),
        value: 'showDetail',
        align: 'center',
        class: 'data-table-header',
        sortable: false
      })
      data.push({
        text: this.$t('road-maintenance/patrol-service.show_on_map'),
        value: 'showMap',
        align: 'center',
        class: 'data-table-header',
        sortable: false
      })

      return data
    },
    active () {
      return this.$route.name === 'ActiveWorkOrders'
    },
    active_header () {
      const newHeader = this.header
      newHeader.splice(3, 2)
      return newHeader
    },
    ...mapGetters(['list', 'pagination', 'cityDistricts', 'permissions']),
    wrapperStyles () {
      return {
        height: this.$vuetify.breakpoint.mdAndUp
          ? 'calc(var(--vh, 1vh) * 100 - 64px)'
          : 'calc(var(--vh, 1vh) * 100 - 56px)'
      }
    }
  },
  watch: {
    resizeMap: debounce(function (newVal) {
      this.$refs.mapWorkOrders.invalidateMapSize()
    }, 200),
    WorkOrderDialog (val) {
      if (!val) {
        this.keyBinder.bind()
      }
    }
  },
  async created () {
    const filters = await api('road-maintenance').get('/work-orders-filters')
    this.filters.subject_report = filters.subject_report
    this.filters.hazard_level = filters.hazard_level
    this.filters.city_districts = filters.city_districts
    this.options.subject_report = filters.subject_report.map(item => item.id)
    this.options.hazard_level = filters.hazard_level.map(item => item.id)
    this.options.city_districts = filters.city_districts.map(item => item.id)
    await this.fetchCityDistricts()
    await this.fetchUiViewConfig()
    await this.fetchPermissions()
  },
  methods: {
    ...mapActions(['fetch', 'fetchCityDistricts', 'fetchUiViewConfig', 'fetchPermissions']),
    getItems () {
      this.invokeGetIcons = true
      const params = {
        pagination: {
          perPage: this.options.itemsPerPage,
          currentPage: this.options.page
        },
        sort: {
          by: this.options.sortBy[0] ? this.options.sortBy[0] : null,
          desc: this.options.sortDesc[0] ? this.options.sortDesc[0] : null
        },
        active: this.active,
        hazard_level: this.options.hazard_level,
        subject_report: this.options.subject_report,
        city_districts: this.options.city_districts,
        from: this.options.from,
        to: this.options.to,
        address: this.options.address,
        tipNumber: this.options.tipNumber
      }
      this.fetch(params)
    },
    formatSqlDateTime (date) {
      return formatSqlDateTime(date)
    },
    closeWorkOrderModal () {
      this.getItems()
      this.WorkOrderDialog = false
      this.workOrder = null
      this.keyBinder.bind()
    },
    showWorkOrderDialog (item) {
      this.keyBinder.unbind()
      this.workOrder = item
      this.WorkOrderDialog = true
    },
    selectIcon (item) {
      this.iconItem = item
      this.resetHistory = true
      this.showHistory = false
      this.resizeMap = true
    },
    showTable () {
      if (!this.tableVisible) {
        this.tableVisible = true
        this.firstPaneSize = 40
        this.secondPaneSize = 60
        this.resizeMap = true
      }
    },
    getOptions (val) {
      this.options.to = val.to
      this.options.from = val.from
      this.options.hazard_level = val.selectedHazardLevel
      this.options.subject_report = val.selectedSubjectReport
      this.options.city_districts = val.selectedCityDistricts
      this.options.address = val.address
      this.options.tipNumber = val.tipNumber
      this.options.page = 1
      this.getItems()
    }
  }
}
</script>
<style scoped>
  .v-data-table-header th {
    white-space: nowrap;
  }
</style>

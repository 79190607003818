<template>
  <map-base
    ref="mapBase"
    :style="mapStyle"
    class="map-work-orders-base"
    :map-options="mapOptions"
    :update-center="resetIcon"
    :map-options-picker-config="mapPickerConfig"
    :initial-map-option-values="initialMapOptionValues"
    @map-options-updated="mapOptionsUpdated"
    @marker-zoom-updated="zoomLevel => $emit('set-history-switch-visible', zoomLevel > 14)"
    @markers:added="handleAddedMarkers"
  >
    <template #mapContent>
      <v-dialog
        v-model="WorkOrderDialog"
        max-width="900px"
        :transition="false"
      >
        <show-work-order
          v-if="WorkOrderDialog"
          :item="item"
          :permissions="permissions"
          @close="withPullData => closeWorkOrderDialog(withPullData)"
        />
      </v-dialog>

      <l-control
        v-if="uiViewConfig && uiViewConfig.map_options && uiViewConfig.map_options[userLocationCheckboxId] && canFindUserLocation"
        position="bottomright"
      >
        <div
          style="background-color: white;"
        >
          <universal-button
            type="base"
            label=""
            @click="onCenterUserLocation"
          >
            <v-progress-circular
              v-if="focusOnCurrentUserLocation"
              indeterminate
              size="20"
              color="primary"
              width="3"
            />

            <v-icon
              v-else
            >
              mdi-crosshairs
            </v-icon>
          </universal-button>
        </div>
      </l-control>
    </template>
  </map-base>
</template>
<script>
import { store } from '@/global/store'
import L from 'leaflet'
import { createNamespacedHelpers } from 'vuex'
import ShowWorkOrder from '@/modules/road-maintenance-module/patrol-service/components/ShowWorkOrder.vue'
import { cloneDeep, isEmpty } from 'lodash'
import MapBase from '@/global/components/map/MapBase.vue'
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
import { LControl } from 'vue2-leaflet'
const { mapActions: mapActionsWorkOrder, mapGetters: mapGettersWorkOrder } = createNamespacedHelpers('road-maintenance/work-order')
const { mapActions: mapActionsWorkOrders, mapGetters: mapGettersWorkOrders } = createNamespacedHelpers('road-maintenance/work-orders')

const DEFAULT_USER_LOCATION_ACCURACY_IN_METERS = 300
const SHOW_USER_LOCATION_CONFIG_ID = 'centerOnUserLocation'
const DELAY_DISPLAYING_USER_LOCATION_IN_MS = 100
const DEFAULT_MAX_ZOOM_FOR_CENTERING_USER_LOCATION = 14
// Leaflet flyTo options
const DEFAULT_FLY_TO_OPTIONS_FOR_CENTERING_USER_LOCATION = {
  duration: 1 // Duration of how long is leaflet flyTo animation in seconds
}
export default {
  name: 'MapActiveWorkOrders',

  components: {
    UniversalButton,
    ShowWorkOrder,
    MapBase,
    LControl
  },

  props: {
    mapOptions: {
      type: Object,
      default: () => ({
        renderer: L.canvas()
      })
    },
    options: {
      type: Object,
      default: () => ({})
    },
    selectedIcon: {
      type: Object,
      default: () => ({})
    },
    keyBinderClass: {
      type: Object,
      default: () => ({})
    },
    showHistory: {
      type: Boolean,
      default: false
    },
    invokeGetIcons: {
      type: Boolean,
      default: true
    },
    cityDistricts: {
      type: Array,
      default: () => ([])
    },
    permissions: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      ShowWorkOrder: false,
      WorkOrderDialog: false,
      markers: {},
      markersClusterGroup: {},
      historyMarkers: [],
      oldSelectedItem: false,
      trafficLightClasses: ['red_traffic_light', 'yellow_traffic_light', 'green_traffic_light', 'white_traffic_light'],
      mapStyle: {
        zIndex: 0,
        flex: 1
      },
      mapPickerConfig: {
        content: [
          {
            title: 'Opcije',
            items: [
              {
                id: SHOW_USER_LOCATION_CONFIG_ID, // This value is in constant so we can remove/add this option later by it
                label: 'Prikaži trenutnu lokaciju'
              },
              {
                id: 'showCityDistricts',
                label: 'Prikaži gradske četvrti'
              }
            ]
          }
        ]
      },
      currentUserLocationMarker: null,
      initialMapOptionValues: [],
      uiViewConfigWatcherTriggered: false,
      mapOptionsValues: {},
      addingUserLocationMarker: false,
      locationNavigatorOptions: {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
      },
      focusOnCurrentUserLocation: true,
      navigatorWatchedId: null,
      userLocationCheckboxId: SHOW_USER_LOCATION_CONFIG_ID,
      canFindUserLocation: false
    }
  },

  emits: [
    'set-history-switch-visible',
    'reset-invoke-get-icons',
    'bind-keyBinder-class',
    'reset-zoom',
    'get-data'
  ],

  computed: {
    ...mapGettersWorkOrder(['item']),

    ...mapGettersWorkOrders(['mapItems', 'icons', 'historyMapItems', 'uiViewConfig', 'commonDataMobileIconsStyles', 'trafficLightsDataMobileIconsStyles']),

    active () {
      return this.$route.name === 'ActiveWorkOrders'
    }
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      async handler (newOptions) {
        this.$refs?.mapBase?.startMapLoader()
        if (
          newOptions &&
          !isEmpty(newOptions) &&
          newOptions.subject_report?.length &&
          newOptions.hazard_level?.length &&
          newOptions.city_districts?.length
        ) {
          await this.getIcons({
            active: this.active,
            from: newOptions.from,
            to: newOptions.to,
            subject_report: newOptions.subject_report,
            hazard_level: newOptions.hazard_level,
            city_districts: newOptions.city_districts,
            address: newOptions.address,
            tipNumber: newOptions.tipNumber
          })
          this.$emit('reset-invoke-get-icons')
          this.mapItems.forEach(item => {
            item.click = () => {
              this.showWorkOrderDialog(item.id)
            }
          })
          const markersConfig = {
            markers: this.mapItems,
            icons: this.icons,
            markerClusterOptions: {
              maxClusterRadius: 40
            }
          }

          // Generate patrol work orders icons
          this.generateMarkers(markersConfig)
        }
        this.$refs?.mapBase?.stopMapLoader()
      }
    },

    selectedIcon (item) {
      if (!isEmpty(item)) {
        const marker = this.$refs.mapBase?.findMarkerById(item.id)
        if (this.oldSelectedItem) {
          const marker2 = this.$refs.mapBase?.findMarkerById(this.oldSelectedItem.id)
          var currentClass2 = marker2.getIcon().options.className
          const oldIcon = L.divIcon({
            className: currentClass2.replace(' selected', ''),
            iconSize: this.trafficLightClasses.includes(currentClass2.replace(' selected', '')) ? [10, 18] : [12, 12]
          })
          marker2.setIcon(oldIcon)
        }
        this.$refs?.mapBase?.$refs?.map?.mapObject?.setView(L.latLng([item.latitude, item.longitude]), 16)
        var currentClass = marker.getIcon().options.className
        const newIcon = L.divIcon({
          className: currentClass + ' selected',
          iconSize: this.trafficLightClasses.includes(currentClass) ? [20, 32] : [32, 32]
        })
        marker.setIcon(newIcon)
        this.oldSelectedItem = item
      }
    },

    async showHistory (state) {
      if (state) {
        this.$refs?.mapBase?.startMapLoader()
        await this.generateHistoryIcons()
        this.$refs?.mapBase?.lockMap()
        this.$refs?.mapBase?.stopMapLoader()
      }
      else {
        this.$refs?.mapBase?.startMapLoader()
        this.$refs?.mapBase?.unlockMap()
        this.$refs?.mapBase?.removeMarkers()
        await this.generateIcons(false)
        this.$refs?.mapBase?.stopMapLoader()
      }
    },

    async invokeGetIcons (state) {
      if (state) {
        await this.generateIcons()
        this.$emit('reset-invoke-get-icons')
      }
    },

    WorkOrderDialog (val) {
      if (!val) {
        this.$emit('bind-keyBinder-class')
      }
    },

    // Calling this watcher only once, at the beginning
    // So we can track changes for map options, avoiding duplicate logic
    uiViewConfig: {
      handler (config) {
        if (!this.uiViewConfigWatcherTriggered) {
          // At the beginning, if showing current location is enabled by config or there is no config defined, display user location by default
          if (!config || !config.map_options || config.map_options[SHOW_USER_LOCATION_CONFIG_ID]) {
            this.initialMapOptionValues = [
              ...this.initialMapOptionValues,
              ...[{
                id: SHOW_USER_LOCATION_CONFIG_ID,
                value: true
              }]
            ]
            this.showCurrentUserLocationOnMap()
          }
          // Otherwise, if showing current location is disabled by config, remove user location marker
          if (!config.map_options[SHOW_USER_LOCATION_CONFIG_ID]) {
            this.uiViewConfigWatcherTriggered = true
            this.removeUserLocationFromMap()
          }
        }
      }
    }
  },

  mounted () {
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  },

  beforeDestroy () {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },

  methods: {
    ...mapActionsWorkOrder(['fetchWorkOrder']),

    ...mapActionsWorkOrders(['getIcons', 'updateUiViewConfig']),

    handleVisibilityChange () {
      // document.hidden is true when user is not on the page (switched tab, locked mobile phone, browser is in the background etc.)
      // Focus on current user location only when user enter the page
      this.focusOnCurrentUserLocation = !document.hidden
    },

    onCenterUserLocation () {
      this.focusOnCurrentUserLocation = true
      // document.documentElement.appendChild(document.createElement('div')).innerHTML = 'tu sam'
    },

    async generateIcons (fitMarkers = true) {
      this.oldSelectedItem = false
      await this.getIcons({
        active: this.active,
        from: this.options.from,
        to: this.options.to,
        subject_report: this.options.subject_report,
        hazard_level: this.options.hazard_level,
        city_districts: this.options.city_districts,
        address: this.options.address,
        tipNumber: this.options.tipNumber
      })
      if (this.mapItems.length) {
        this.mapItems.forEach(item => {
          item.click = () => {
            this.showWorkOrderDialog(item.id)
          }
        })
        const markersConfig = {
          markers: this.mapItems,
          icons: this.icons,
          markerClusterOptions: {
            maxClusterRadius: 40
          },
          fitMarkers: fitMarkers
        }
        this.generateMarkers(markersConfig)
      }
      else {
        this.$refs.mapBase?.removeMarkers()
      }
    },

    async generateHistoryIcons () {
      const bounds = this.$refs?.mapBase?.getCurrentMapBounds()
      const minLat = bounds._southWest.lat
      const minLon = bounds._southWest.lng
      const maxLat = bounds._northEast.lat
      const maxLon = bounds._northEast.lng
      await this.getIcons({
        active: false,
        min_lat: minLat,
        min_lon: minLon,
        max_lat: maxLat,
        max_lon: maxLon,
        from: this.options.from,
        subject_report: this.options.subject_report,
        hazard_level: this.options.hazard_level,
        city_districts: this.options.city_districts,
        address: this.options.address,
        tipNumber: this.options.tipNumber
      })
      this.historyMapItems.forEach(item => {
        item.click = () => {
          this.showWorkOrderDialog(item.id)
        }
      })
      const markersConfig = {
        markers: this.historyMapItems,
        icons: this.icons,
        keepExisting: true,
        markerClusterOptions: {
          maxClusterRadius: 40
        },
        fitMarkers: false
      }
      this.generateMarkers(markersConfig)
      this.$emit('reset-invoke-get-icons')
    },

    async mapOptionsUpdated (updatedOptions) {
      this.mapOptionsValues = updatedOptions
      const existingMapOptions = this.uiViewConfig?.map_options ?? {}

      // ------------ Show city districts handler -------------
      if (updatedOptions.showCityDistricts) {
        if (this.cityDistricts && this.cityDistricts.length) {
          const polygonConfig = {
            polygons: []
          }
          this.cityDistricts.forEach(cityDistrict => {
            if (cityDistrict && !isEmpty(cityDistrict) && cityDistrict.data) {
              const coordinatesString = cityDistrict.data.match(/\(\(([^)]+)\)\)/)[1]

              const coordinatesPairs = coordinatesString.split(', ')

              const coordinatesArray = coordinatesPairs.map(pair => {
                const [latitude, longitude] = pair.split(' ').map(parseFloat)
                return [latitude, longitude]
              })
              polygonConfig.polygons.push({
                coordinates: coordinatesArray,
                label: cityDistrict.name,
                options: {
                  color: cityDistrict.color,
                  className: 'polygon-label'
                }
              })
              this.$refs?.mapBase?.generatePolygons(polygonConfig)
            }
          })
        }
      }
      else {
        this.$refs?.mapBase?.onMapReady(function (map) {
          map.removeAllPolygons()
        })
      }

      // ---------------- Show user location handler ------------------
      if (this.uiViewConfigWatcherTriggered) {
        if (updatedOptions[SHOW_USER_LOCATION_CONFIG_ID]) {
          this.focusOnCurrentUserLocation = true
          // Get updated location and show it on map
          this.getCurrentUserLocationsMarkerConfig()

          // Make new update config for sending to backend
          const updatedConfig = {
            config: {
              ...(this.uiViewConfig ?? {}),
              map_options: {
                ...existingMapOptions,
                [SHOW_USER_LOCATION_CONFIG_ID]: true
              }
            }
          }

          // Remember chosen option in ui view config
          await this.updateUiViewConfig(updatedConfig)
        }
        else {
          this.removeUserLocationFromMap()
          // Make new update config for sending to backend
          const updatedConfig = {
            config: {
              ...(this.uiViewConfig ?? {}),
              map_options: {
                ...existingMapOptions,
                [SHOW_USER_LOCATION_CONFIG_ID]: false
              }
            }
          }

          // Remember chosen option in ui view config
          await this.updateUiViewConfig(updatedConfig)
        }
      }

      // Set flag that ui view config is triggered, if not yet
      if (!this.uiViewConfigWatcherTriggered) {
        this.uiViewConfigWatcherTriggered = true
      }
    },

    showCurrentUserLocationOnMap () {
      const that = this

      if (this.currentUserLocationMarker && this.currentUserLocationMarker.lat && this.currentUserLocationMarker.lon) {
        // Add user location marker on map
        this.$refs?.mapBase?.onMapReady(function (map) {
          that.addingUserLocationMarker = true
          map.removeMarkerById(that.currentUserLocationMarker.id)
          map.generateMarkers({
            markers: [that.currentUserLocationMarker],
            icons: that.icons,
            keepExisting: true,
            fitMarkers: that.focusOnCurrentUserLocation
          })

          if (that.focusOnCurrentUserLocation) {
            that.focusOnCurrentUserLocation = false
          }
        })
      }
      else {
        this.getCurrentUserLocationsMarkerConfig()
      }
    },

    handleAddedMarkers () {
      const that = this

      if (this.addingUserLocationMarker && this.focusOnCurrentUserLocation) {
        setTimeout(() => {
          that.$refs?.mapBase?.flyTo(that.currentUserLocationMarker, DEFAULT_MAX_ZOOM_FOR_CENTERING_USER_LOCATION, DEFAULT_FLY_TO_OPTIONS_FOR_CENTERING_USER_LOCATION)
          that.addingUserLocationMarker = false
        }, DELAY_DISPLAYING_USER_LOCATION_IN_MS)
      }
    },

    async updateUiViewConfig (config) {
      await this.$store.dispatch('road-maintenance/work-orders/updateUiViewConfig', config)
    },

    removeUserLocationFromMap () {
      const that = this
      // Stop watching current user location when user uncheck checkbox for showing current user location
      navigator.geolocation.clearWatch(this.navigatorWatchedId)
      if (that.currentUserLocationMarker && that.currentUserLocationMarker.id) {
        this.$refs?.mapBase?.onMapReady(function (map) {
          map.removeMarkerById(that.currentUserLocationMarker.id)
        })
      }
    },

    generateMarkers (markersConfig) {
      markersConfig = cloneDeep(markersConfig)
      markersConfig.fitBoundsOptions = {
        pad: 0.5
      }

      if (this.$vuetify.breakpoint.mobile && markersConfig && markersConfig.icons && !isEmpty(markersConfig.icons)) {
        const commonShapes = ['circle', 'triangle', 'square']
        for (const key in markersConfig.icons) {
          const className = markersConfig?.icons[key]?.options?.className ?? ''
          const iconOption = markersConfig?.icons[key]?.options ?? null

          if (className && iconOption) {
            const isCommonShape = commonShapes.some(shape => className.includes(shape))

            if (isCommonShape && this.commonDataMobileIconsStyles && !isEmpty(this.commonDataMobileIconsStyles)) {
              Object.assign(iconOption, this.commonDataMobileIconsStyles)
            }
            else if (this.trafficLightsDataMobileIconsStyles && !isEmpty(this.trafficLightsDataMobileIconsStyles)) {
              Object.assign(iconOption, this.trafficLightsDataMobileIconsStyles)
            }
          }
        }
      }
      markersConfig.fitMarkers = !(this.currentUserLocationMarker && this.currentUserLocationMarker.lat && this.currentUserLocationMarker.lon && this.mapOptionsValues[SHOW_USER_LOCATION_CONFIG_ID])

      this.$refs?.mapBase?.onMapReady(function (map) {
        map.generateMarkers(markersConfig)
      })
    },

    async showWorkOrderDialog (id) {
      if (this.keyBinderClass) {
        this.keyBinderClass.unbind()
      }
      await this.fetchWorkOrder(id)
      this.WorkOrderDialog = true
    },

    // Invalidate map size function to be called from parent component so the missing tiles are fetched
    invalidateMapSize () {
      this.$refs?.mapBase?.getMapObject()?.invalidateSize()
    },

    resetIcon (value) {
      if (!isEmpty(this.selectedIcon)) {
        const marker = this.$refs.mapBase?.findMarkerById(this.selectedIcon.id)
        if ((value.lat.toFixed(4) !== this.selectedIcon.latitude.toFixed(4) || value.lng.toFixed(4) !== this.selectedIcon.longitude.toFixed(4)) && marker) {
          const currentClass2 = marker.getIcon().options.className
          const oldIcon = L.divIcon({
            className: currentClass2.replace(' selected', ''),
            iconSize: this.trafficLightClasses.includes(currentClass2.replace(' selected', '')) ? [10, 18] : [12, 12]
          })
          marker.setIcon(oldIcon)
          this.$emit('reset-zoom')
        }
      }
    },

    closeWorkOrderDialog (withPullData) {
      this.WorkOrderDialog = false
      if (withPullData) {
        this.$emit('get-data')
      }
    },

    removeShowLocationOption () {
      const mapOptionToDeleteIndex = this.mapPickerConfig?.content[0]?.items?.findIndex(option => option.id === SHOW_USER_LOCATION_CONFIG_ID)
      if (mapOptionToDeleteIndex !== undefined && mapOptionToDeleteIndex !== -1) {
        this.mapPickerConfig.content[0].items.splice(mapOptionToDeleteIndex, 1)
      }
    },

    getCurrentUserLocationsMarkerConfig () {
      if (navigator.geolocation) {
        navigator.geolocation.clearWatch(this.navigatorWatchedId)

        this.navigatorWatchedId = navigator.geolocation.watchPosition(
          position => {
            const { latitude, longitude, accuracy } = position.coords

            this.canFindUserLocation = accuracy < DEFAULT_USER_LOCATION_ACCURACY_IN_METERS
            // If user location can be identified (accuracy of retrieved position is in allowed range), remember position
            if (this.canFindUserLocation) {
              this.currentUserLocationMarker = {
                id: 'user_location',
                lat: latitude,
                lon: longitude,
                icon: 'user_location',
                alwaysVisible: true
              }
              this.showCurrentUserLocationOnMap()
            }
            // Otherwise, user location cant be identified, so we remove 'show current location' option from map options
            else {
              this.removeShowLocationOption()
            }
          },
          error => {
            if (!error || error.code == null) {
              store.dispatch('base/notifications/push', 'Došlo je do nepoznate pogreške pri dohvaćanju lokacije')
              return
            }

            const messages = {
              1: 'Ecomobile karte nemaju dopuštenje za korištenje vaše lokacije',
              2: 'Trenutna lokacija nije dostupna',
              3: 'Vrijeme dopušteno za dobivanje geolokacije je dosegnuto prije dobivanja informacija'
            }

            store.dispatch('base/notifications/push', messages[error.code] || 'Došlo je do pogreške pri dobivanju lokacije')
          },
          this.locationNavigatorOptions
        )
      }
      else {
        store.dispatch('base/notifications/push', 'Trenutna lokacija nije dostupna')
      }
    }
  }
}
</script>

<style scoped>
/*
  If multiple controls are added into the controls slot, they should be separated with a v-divider.
  This styles the v-divider to maintain consistent spacing.
*/
.map-work-orders-base {
  .leaflet-control .v-divider{
    margin: 8px 0;
  }
  .v-icon {
    font-size: 1.5rem;
  }
  .v-label {
    font-size: 0.82rem;
  }
  .red,
  .yellow,
  .green,
  .white,
  .red_traffic_light,
  .yellow_traffic_light,
  .green_traffic_light,
  .white_traffic_light{
    box-shadow: 0px 0px 10px 2px;
  }
  .red:hover,
  .yellow:hover,
  .green:hover,
  .white:hover,
  .red_traffic_light:hover,
  .yellow_traffic_light:hover,
  .green_traffic_light:hover,
  .white_traffic_light:hover,
  .selected {
    box-shadow: 0px 0px 10px 2px orange;
  }
  .circle {
    border-radius: 50%;
  }

  .red_traffic_light,
  .yellow_traffic_light,
  .green_traffic_light,
  .white_traffic_light {
    width: 8px;
    height: 18px;
    border: 1px solid #333;
    border-radius: 2px;
    position: relative;
  }

  .red_traffic_light {
    background-color: rgb(244, 67, 54);
  }

  .yellow_traffic_light {
    background-color: rgb(255, 235, 59);
  }

  .green_traffic_light {
    background-color: rgb(76, 175, 80);
  }

  .white_traffic_light {
    background-color: rgb(255, 255, 255);
  }

  .red_traffic_light::before,
  .yellow_traffic_light::before,
  .green_traffic_light::before,
  .white_traffic_light::before,
  .red_traffic_light::after,
  .yellow_traffic_light::after,
  .green_traffic_light::after,
  .white_traffic_light::after {
    content: "";
    width: 50%;
    height: 30%;
    border-radius: 50%;
    border: 1px solid #000;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .red_traffic_light::before,
  .yellow_traffic_light::before,
  .green_traffic_light::before,
  .white_traffic_light::before {
    top: 12%;
  }

  .red_traffic_light::after,
  .yellow_traffic_light::after,
  .green_traffic_light::after,
  .white_traffic_light::after {
    bottom: 12%;
  }

  .red_triangle {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    border-bottom: 14px solid rgb(244, 67, 54);
    -webkit-filter: drop-shadow(3px 3px 3px  #000);
    filter: drop-shadow(3px 3px 3px  #000);
  }

  .red_triangle:hover {
    -webkit-filter: drop-shadow(3px 3px 3px orange);
    filter: drop-shadow(3px 3px 3px orange);
  }

  .red_triangle.selected {
    width: 0;
    height: 0;
    border: 23px solid transparent;
    border-top: 0;
    box-shadow: none;
    border-bottom: 27px solid rgb(244, 67, 54);
    -webkit-filter: drop-shadow(3px 3px 3px orange);
    filter: drop-shadow(3px 3px 3px orange);
  }

  .yellow_triangle {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    border-bottom: 14px solid rgb(255, 235, 59);
    -webkit-filter: drop-shadow(3px 3px 3px  #000);
    filter: drop-shadow(3px 3px 3px  #000);
  }

  .yellow_triangle:hover {
    -webkit-filter: drop-shadow(3px 3px 3px  orange);
    filter: drop-shadow(3px 3px 3px  orange);
  }

  .yellow_triangle.selected {
    width: 0;
    height: 0;
    border: 23px solid transparent;
    border-top: 0;
    box-shadow: none;
    border-bottom: 27px solid rgb(255, 235, 59);
    -webkit-filter: drop-shadow(3px 3px 3px orange);
    filter: drop-shadow(3px 3px 3px orange);
  }

  .green_triangle {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    border-bottom: 14px solid rgb(76, 175, 80);
    -webkit-filter: drop-shadow(3px 3px 3px  #000);
    filter: drop-shadow(3px 3px 3px  #000);
  }

  .green_triangle:hover {
    -webkit-filter: drop-shadow(3px 3px 3px  orange);
    filter: drop-shadow(3px 3px 3px  orange);
  }

  .green_triangle.selected {
    width: 0;
    height: 0;
    border: 23px solid transparent;
    border-top: 0;
    box-shadow: none;
    border-bottom: 27px solid rgb(76, 175, 80);
    -webkit-filter: drop-shadow(3px 3px 3px orange);
    filter: drop-shadow(3px 3px 3px orange);
  }

  .white_triangle {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    border-bottom: 14px solid rgb(255, 255, 255);
    -webkit-filter: drop-shadow(3px 3px 3px  #000);
    filter: drop-shadow(3px 3px 3px  #000);
  }

  .white_triangle:hover {
    -webkit-filter: drop-shadow(3px 3px 3px  orange);
    filter: drop-shadow(3px 3px 3px  orange);
  }

  .white_triangle.selected {
    width: 0;
    height: 0;
    border: 23px solid transparent;
    border-top: 0;
    box-shadow: none;
    border-bottom: 27px solid rgb(255, 255, 255);
    -webkit-filter: drop-shadow(3px 3px 3px orange);
    filter: drop-shadow(3px 3px 3px orange);
  }

  .polygon-label {
    background-color: blue;
  }

  //Set current user location marker under the other markers
  .user_location {
    z-index: 100 !important;
  }
}
</style>

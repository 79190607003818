<template>
  <div
    class="overflow-y-hidden"
    :style="wrapperStyles"
  >
    <!-- Desktop view -->
    <splitpanes
      horizontal
      :push-other-panes="false"
      class="default-theme h-full"
      @resize="resizeMap = !resizeMap"
    >
      <pane
        :size="firstPaneSize"
        max-size="88"
        min-size="12"
      >
        <!-- lazy because of problems with vuetify and vue-leaflet https://github.com/vue-leaflet/Vue2Leaflet/issues/96 -->
        <v-lazy class="h-full">
          <!-- Important to have wrapping div when using `v-lazy`, otherwise map layout will be broken -->
          <div class="h-full">
            <excavation-order-map
              ref="excavationOrderMap"
              class="h-full"
              :invokeGetIcons="invokeGetIcons"
              :show-history="showHistory"
              :options="options"
              :permissions="permissions"
              :city-districts="cityDistricts"
              :selected-icon="iconItem"
              @pull-data="pullData"
              @close-edit-dialog="closeEditDialog"
              @open-edit-dialog-map="openEditDialogMap"
              @reset-invoke-get-icons="invokeGetIcons = false"
              @reset-zoom="iconItem = {}"
              @set-history-switch-visible="visible => historySwitchVisible = visible"
            />
          </div>
        </v-lazy>
      </pane>
      <pane
        :size="secondPaneSize"
        min-size="12"
        max-size="88"
        class="overflow-y-auto"
      >
        <v-container fluid>
          <v-card>
            <v-card-text>
              <excavation-order-controls
                :history-switch-visible="historySwitchVisible"
                :reset-history="resetHistory"
                :permissions="permissions"
                :open-edit-dialog="edit"
                :edit-item-prop="editItem"
                :options.sync="options"
                :map-marker-clicked="clickedMapMarker"
                :to-date="toDateFromCreateOrUpdate"
                :city-districts="cityDistricts"
                @close-edit-dialog="closeEditDialog"
                @show-table="showTable"
                @get-options="getOptions"
                @pull-data="pullData"
                @show-history="setShowHistory"
              />
              <v-data-table
                v-show="tableVisible"
                v-stripped
                class="fill-height overflow-y-auto"
                :headers="header"
                item-key="id"
                :items="list"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.perPage"
                :sort-by.sync="options.sortBy"
                :sort-desc.sync="options.sortDesc"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15]
                }"
                @update:options="getItems"
              >
                <template #[`item.label`]="{ item }">
                  {{ item.label ? item.label : '-' }}
                </template>
                <template #[`item.consent_number`]="{ item }">
                  {{ item.consent_number ? item.consent_number : '-' }}
                </template>
                <template #[`item.investor`]="{ item }">
                  {{ item.investor ? item.investor.name : '-' }}
                </template>
                <template #[`item.applicant`]="{ item }">
                  {{ item.applicant ? item.applicant.name : '-' }}
                </template>
                <template #[`item.contractor`]="{ item }">
                  {{ item.contractor ? item.contractor.name : '-' }}
                </template>
                <template #[`item.address`]="{ item }">
                  {{ item.address ? item.address : '-' }}
                </template>
                <template #[`item.consented_at`]="{ item }">
                  {{ item.consented_at ? formatSqlDate(item.consented_at) : '-' }}
                </template>
                <template #[`item.work_started_at`]="{ item }">
                  {{ item.work_started_at ? formatSqlDate(item.work_started_at) : '-' }}
                </template>
                <template #[`item.work_completed_at`]="{ item }">
                  {{ item.work_completed_at ? formatSqlDate(item.work_completed_at) : '-' }}
                </template>
                <template #[`item.notification_at`]="{ item }">
                  {{ item.notification_at ? formatSqlDate(item.notification_at) : '-' }}
                </template>
                <template #[`item.handed_over_at`]="{ item }">
                  {{ item.handed_over_at ? formatSqlDate(item.handed_over_at) : '-' }}
                </template>
                <template #[`item.completed_at`]="{ item }">
                  {{ item.completed_at ? formatSqlDate(item.completed_at) : '-' }}
                </template>
                <template #[`item.billed_at`]="{ item }">
                  {{ item.billed_at ? formatSqlDate(item.billed_at) : '-' }}
                </template>
                <template #[`item.extended`]="{ item }">
                  {{ item.extended ? $t('base.yes').toUpperCase() : $t('base.no').toUpperCase() }}
                </template>
                <template #[`item.showMap`]="{ item }">
                  <v-btn
                    icon
                    @click="selectIcon(item)"
                  >
                    <v-icon>
                      mdi-map-marker-radius
                    </v-icon>
                  </v-btn>
                </template>
                <template
                  #[`item.actions`]="{ item }"
                >
                  <v-menu
                    offset-y
                    offset-overflow
                    left
                  >
                    <template #activator="{ on }">
                      <v-icon
                        v-on="on"
                      >
                        mdi-dots-vertical
                      </v-icon>
                    </template>

                    <v-list class="pa-0">
                      <v-list-item
                        class="d-flex align-center list-item-min-height"
                        link
                        @click="openEditDialog(item)"
                      >
                        <v-list-item-icon class="align-self-center my-0 mr-2">
                          <v-icon small>
                            mdi-pencil
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-title class="main-font-size">
                          {{ $t('base.edit') }}
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        v-if="canDelete"
                        class="d-flex align-center list-item-min-height"
                        link
                        @click="openDeleteDialog(item)"
                      >
                        <v-list-item-icon class="align-self-center my-0 mr-2">
                          <v-icon small>
                            mdi-delete
                          </v-icon>
                        </v-list-item-icon>

                        <v-list-item-title class="main-font-size">
                          {{ $t('base.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-container>
      </pane>
    </splitpanes>
    <DeleteWorkOrdersModal
      :open-modal="openDeleteOrderModal"
      :id="deleteId"
      @close-work-order-confirmed="deleteConfirmed"
      @close-work-order-canceled="cancelDelete"
    />
  </div>
</template>

<script>
import { debounce, forEach } from 'lodash'
import { createNamespacedHelpers } from 'vuex'
import ExcavationOrderMap from '@/modules/road-maintenance-module/excavation-service/components/ExcavationOrderMap.vue'
import ExcavationOrderControls from '@/modules/road-maintenance-module/excavation-service/components/ExcavationOrderControls.vue'
import DeleteWorkOrdersModal from '../components/DeleteWorkOrderModal.vue'
import { formatSqlDate, formatSqlDateTime } from '@/global/services/helpers/dates'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import { api } from '../../../../global/services/api'
import store from '@/global/store'
const { mapGetters, mapActions } = createNamespacedHelpers('road-maintenance/excavation-orders')
export default {
  name: 'ExcavationOrderIndex',
  components: {
    ExcavationOrderMap,
    ExcavationOrderControls,
    Splitpanes,
    Pane,
    DeleteWorkOrdersModal
  },

  data () {
    return {
      resizeMap: false,
      workOrder: false,
      WorkOrderDialog: false,
      tableVisible: false,
      firstPaneSize: 85,
      secondPaneSize: 15,
      toDateFromCreateOrUpdate: '',
      historySwitchVisible: false,
      showHistory: false,
      resetHistory: false,
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: ['id'],
        sortDesc: [true],
        active: false,
        excavationOrderTypes: [],
        from: '',
        to: '',
        consentNumber: '',
        address: '',
        year: '',
        cityDistricts: [],
        dateKey: ''
      },
      edit: false,
      editItem: {},
      openDeleteOrderModal: false,
      deleteId: 0,
      invokeGetIcons: false,
      clickedMapMarker: false,
      iconItem: {}
    }
  },
  computed: {
    ...mapGetters(['list', 'pagination', 'permissions', 'cityDistricts']),

    header () {
      const data = []
      const columns = [
        'label',
        'consent_number',
        'investor',
        'applicant',
        'contractor',
        'address',
        'consented_at',
        'work_started_at',
        'work_completed_at',
        'notification_at',
        'handed_over_at',
        'completed_at',
        'billed_at',
        'extended'
      ]
      const translatedColumns = [
        this.$t('road-maintenance/excavation-service/tabs/request/columns.label'),
        this.$t('road-maintenance/excavation-service/tabs/approval/columns.consent_number'),
        this.$t('road-maintenance/dropdown-types.investor'),
        this.$t('road-maintenance/dropdown-types.applicant'),
        this.$t('road-maintenance/dropdown-types.contractor'),
        this.$t('road-maintenance/excavation-service/tabs/request/columns.address'),
        this.$t('road-maintenance/excavation-service/tabs/approval/columns.consent_date'),
        this.$t('road-maintenance/excavation-service/tabs/approval/columns.start_date_of_work'),
        this.$t('road-maintenance/excavation-service/tabs/approval/columns.end_date_of_work'),
        this.$t('road-maintenance/excavation-service.notification_at'),
        this.$t('road-maintenance/excavation-service/tabs/handover/columns.handover_date'),
        this.$t('road-maintenance/excavation-service/tabs/cleanup/columns.cleanup_date'),
        this.$t('road-maintenance/excavation-service/tabs/invoice/columns.invoice_date'),
        this.$t('road-maintenance/excavation-service.extended')
      ]
      forEach(columns, function (column, key) {
        data.push({
          text: translatedColumns[key],
          value: column,
          align: 'center',
          class: 'data-table-header'
        })
      })
      data.push({
        text: this.$t('road-maintenance/patrol-service.show_on_map'),
        value: 'showMap',
        align: 'center',
        class: 'data-table-header',
        sortable: false
      })
      data.push({
        text: this.$t('base.actions'),
        value: 'actions',
        align: 'center',
        class: 'data-table-header',
        sortable: false
      })

      return data
    },

    active () {
      return this.$route.name === 'ExcavationActiveWorkOrders'
    },

    wrapperStyles () {
      return {
        height: this.$vuetify.breakpoint.mdAndUp
          ? 'calc(var(--vh, 1vh) * 100 - 64px)'
          : 'calc(var(--vh, 1vh) * 100 - 56px)'
      }
    },

    canDelete () {
      const [permissions] = this.permissions
      return this.$route.name === 'ExcavationActiveWorkOrders'
        ? permissions?.deleteActive
        : permissions?.deleteInactive
    }
  },

  watch: {
    resizeMap: debounce(function () {
      this.$refs.excavationOrderMap.invalidateMapSize()
    }, 200)
  },

  async created () {
    await this.getYear()
    await this.getPermissions()
    await this.fetchCityDistricts()
  },

  methods: {
    ...mapActions(['fetch', 'fetchPermissions', 'fetchCityDistricts']),

    getItems () {
      const params = {
        pagination: {
          perPage: this.options.itemsPerPage,
          currentPage: this.options.page
        },
        table: true,
        sort: {
          by: this.options.sortBy[0] ? this.options.sortBy[0] : null,
          desc: this.options.sortDesc[0] ? this.options.sortDesc[0] : null
        },
        active: this.active,
        statuses: this.options.excavationOrderTypes,
        from: this.options.from,
        to: this.options.to,
        consentNumber: this.options.consentNumber,
        address: this.options.address,
        year: this.options.year,
        cityDistricts: this.options.cityDistricts,
        dateKey: this.options.dateKey
      }
      if (params.statuses && params.statuses.length && params.from && params.to) {
        this.fetch(params)
      }
    },

    pullData () {
      this.invokeGetIcons = true
      this.getItems()
    },

    setShowHistory (value) {
      this.showHistory = value

      // If show history is turned on, reset remembered selected marker
      // Without this line, after turning off showing history, if user clicks again on the same marker as before showing history, new selected marker will not be shown
      if (value) {
        this.iconItem = {}
      }
    },

    selectIcon (item) {
      // If showing history is turned on, disable showing marker from data table
      // Disabling because all the map's interactions are disabled when history markers are displayed
      if (this.showHistory) {
        store.dispatch('base/notifications/push', 'Nije moguće prikazati marker kada je prikaz povijesnih podataka uključen')
      }
      else {
        this.iconItem = item
        this.resetHistory = true
        this.resizeMap = true
      }
    },

    openEditDialogMap () {
      this.clickedMapMarker = true
    },

    async getPermissions () {
      await this.fetchPermissions()
    },

    closeEditDialog () {
      this.clickedMapMarker = false
      this.edit = false
      this.editItem = {}
    },

    formatSqlDateTime (date) {
      return formatSqlDateTime(date)
    },

    formatSqlDate (date) {
      return formatSqlDate(date)
    },

    showTable () {
      if (!this.tableVisible) {
        this.tableVisible = true
        this.firstPaneSize = 40
        this.secondPaneSize = 60
        this.resizeMap = true
      }
      this.pullData()
    },

    getOptions (val) {
      this.options.to = val.to
      this.options.from = val.from
      this.options.excavationOrderTypes = val.excavationOrderTypes
      this.options.consentNumber = val.consentNumber
      this.options.address = val.address
      this.options.year = val.year
      this.options.cityDistricts = val.cityDistricts
      this.options.dateKey = val.dateKey
      this.options.page = 1
    },

    async openEditDialog (item) {
      try {
        const response = await api()['road-maintenance'].get('excavation-orders/' + item.id)
        this.edit = true
        this.editItem = response.data
      }
      catch (e) {
        console.log(e)
      }
    },

    openDeleteDialog (item) {
      this.deleteId = item.id
      this.openDeleteOrderModal = true
    },

    async deleteConfirmed (id) {
      try {
        await api()['road-maintenance'].delete('excavation-orders/' + id)
        this.openDeleteOrderModal = false
        this.pullData()
      }
      catch (e) {
        console.log(e)
      }
    },

    cancelDelete () {
      this.openDeleteOrderModal = false
    },

    async getYear () {
      this.options.year = await api('road-maintenance').get('/excavation-order/get-filter-year')
    }
  }
}
</script>

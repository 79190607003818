<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          v-for="(image, index) in filteredImages"
          :key="index"
          class="d-flex child-flex"
          :cols="12 / filteredImages.length"
        >
          <v-img
            :src="image"
            :lazy-src="image"
            class="bg-grey-lighten-2"
            @error="imageLoadFailed(image)"
          >
            <template #placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey-lighten-5"
                />
              </v-row>
            </template>
            <v-btn
              color="primary"
              primary
              class="ma-3"
              @click="downloadImage(image, 'Fotografija' + (index + 1))"
            >
              {{ $t('base/patrol-vehicle.download') }}
            </v-btn>
          </v-img>
        </v-col>
      </v-row>
      <v-row
        v-if="failedImages && images && images.length && failedImages.length && failedImages.length === images.length"
      >
        <v-col
          style="font-weight: 500; color: black;"
        >
          Fotografije se nisu uspješno učitale.
        </v-col>
      </v-row>
      <v-row class="ma-0 flex flex-row-reverse mt-4">
        <v-btn
          color="primary"
          primary
          class="px-7 ml-2 mt-3"
          @click="closeDialog"
        >
          {{ $t('base.close') }}
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ImageGallery',

  props: {
    images: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      failedImages: []
    }
  },

  computed: {
    filteredImages () {
      return this.images.filter(image => !this.failedImages.includes(image))
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close-image-gallery')
    },

    async downloadImage (imageUrl, title) {
      try {
        const blob = await (await fetch(imageUrl)).blob()
        const url = URL.createObjectURL(blob)
        Object.assign(document.createElement('a'), { href: url, download: title + '.jpg' }).click()
        URL.revokeObjectURL(url)
      }
      catch (error) {
        console.error('Failed to download image:', error)
      }
    },

    imageLoadFailed (image) {
      this.failedImages.push(image)
    }
  }
}
</script>
